import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import ChangeLogItem from "./ChangeLogItem";
import firebase from "firebase/app";
import "firebase/database";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { setUserValue } from "../../redux/action/userAction";
import { Row, Col } from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";
import no_post_img from "../../assets/images/no-posts.png";
import "./ChangeLog.scss";
import AddNewChangelogModal from "../../components/modals/AddNewChangelog";

class ChangeLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { allChangelogs: [], isLoading: true };
  }

  componentDidMount = async () => {
    const { subdomain, companyExists } = this.props;

    if (!companyExists) {
      this.props.history.push("/error");
      return;
    }

    const changelogsRef = await firebase
      .database()
      .ref("company-changelogs")
      .child(subdomain)
      .orderByChild("createdAt")
      .once("value");

    if (changelogsRef.val() === null || changelogsRef.val() === undefined) {
      this.setState({ isLoading: false });
      return;
    }

    var changelogsArray = [];
    changelogsRef.forEach((changelog) => {
      changelogsArray.push(changelog.val());
    });

    this.setState({
      allChangelogs: changelogsArray.reverse(),
      isLoading: false,
    });
  };

  onAddNewChangelog = () => {
    this.props.setUserValue({ showAddNewChangelogModal: true });
  };

  render() {
    const { allChangelogs, isLoading } = this.state;
    const { subdomain, company } = this.props;
    const subdomainCap = subdomain.charAt(0).toUpperCase() + subdomain.slice(1);
    const { currentUser } = this.props.user;

    if (isLoading) {
      return (
        <div className="text-center" style={{ marginTop: 150 }}>
          <PulseLoader sizeUnit={"px"} size={8} color={"#66b0ff"} />
        </div>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>{"Changelog | " + subdomainCap}</title>
          <meta
            name="description"
            content={
              "Report bugs or request features for " +
              subdomainCap +
              " on backlogs.co"
            }
          />
        </Helmet>
        <AddNewChangelogModal subdomain={subdomain} />
        <section className="changelog-container">
          <div className="container top-wrap">
            <div className="row justify-content-md-center pt-3 pt-lg-4 changelogItem">
              <div className="col-12">
                {currentUser &&
                  currentUser.companies &&
                  currentUser.companies[subdomain] && (
                    <button
                      className="new-changelog-btn"
                      onClick={() => this.onAddNewChangelog()}
                    >
                      👉 Add New Changelog
                    </button>
                  )}
                {allChangelogs.length === 0 ? (
                  <div className="container">
                    <Row className="justify-content-center">
                      <Col lg={8} md={12} className="text-center">
                        <img
                          src={no_post_img}
                          className="img-fluid"
                          alt=""
                          style={{ width: "420px" }}
                        />
                        <div className="text-capitalize text-dark mb-4 error-page">
                          No items in changelog.
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  allChangelogs.map((changelog) => {
                    return (
                      <ChangeLogItem
                        key={changelog.createdAt}
                        changelog={changelog}
                      />
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChangeLog);
