import React from "react";
import firebase from "firebase/app";
import "firebase/database";
import { toast } from "react-toastify";
import { Form, InputGroup } from "react-bootstrap";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { faFileImage } from "@fortawesome/free-solid-svg-icons";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class CompanySettings extends React.Component {
  constructor(props) {
    super(props);
    const { currentUser } = this.props;
    this.state = {
      name: "",
      website: "",
      subdomain: "",
      logoImg: "",
      updatedLogo: false,
      loading: false,
    };
    this.onChangeText = this.onChangeText.bind(this);
  }

  componentDidMount() {
    const { company } = this.props;
    this.setState({
      name: company ? company.name : "",
      website: company ? company.website : "",
      subdomain: company ? company.subdomain : "",
      logoImg: company ? company.logo : "",
    });
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.company !== prevProps.company) {
      const { company } = this.props;
      this.setState({
        name: company ? company.name : "",
        website: company ? company.website : "",
        subdomain: company ? company.subdomain : "",
        logoImg: company ? company.logo : "",
      });
    }
  };

  onChangeText = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  onUpdateSettings = async () => {
    const { name, website, subdomain, logoImg, updatedLogo } = this.state;
    const { currentUser, company } = this.props;

    const subdomainLowerCase = subdomain.toLowerCase();
    //check if subdomain is changed. then check if subdomain is taken or available
    if (!company) {
      //check is new subdomain is valid in regex
      var subdomainRegex = /^([a-zA-Z0-9\-]{2,})+$/;
      if (subdomainLowerCase.match(subdomainRegex) === null) {
        //didn't pass the subdomain validator, toast error and return
        if (subdomainLowerCase.length < 2) {
          toast.error("subdomain must be at least 2 characters long", {
            autoClose: 4000,
          });
          return;
        }
        toast.error(
          'Your subdomain can only contain letters, numbers and "-"',
          {
            autoClose: 4000,
          }
        );
        return;
      }

      const subdomainRef = await firebase
        .database()
        .ref("subdomains")
        .child(subdomainLowerCase)
        .once("value");

      if (subdomainRef.exists() === false) {
        await firebase //add new subdomain
          .database()
          .ref("subdomains")
          .child(subdomainLowerCase)
          .set({
            name: name,
            website: website,
            subdomain: subdomain,
            admin: {
              name: currentUser.displayName,
              email: currentUser.email,
              uid: currentUser.uid,
            },
          });
      } else {
        //subdomain is taken
        toast.error("Sorry, this subdomain is taken.", {
          autoClose: 4000,
        });
        return;
      }
    }

    var fileURL = "";
    if (logoImg !== "" && updatedLogo) {
      var storageRef = await firebase.storage().ref(currentUser.uid);
      var logoName = "logo" + Date();
      var fileRef = storageRef.child("products").child(name).child(logoName);
      var type = logoImg.split(";")[0].split(":")[1];

      var uploadedFile = await fileRef.putString(
        logoImg.split(",")[1],
        "base64",
        {
          contentType: type,
        }
      );
      fileURL = await uploadedFile.ref.getDownloadURL();
    } else {
      fileURL = logoImg;
    }

    await firebase //add new subdomain
      .database()
      .ref("subdomains")
      .child(subdomainLowerCase)
      .update({
        logo: fileURL,
      });

    await firebase
      .database()
      .ref("users")
      .child(this.props.currentUser.uid)
      .child("companies")
      .child(subdomain)
      .update({
        name: name,
        website: website,
        subdomain: subdomain,
        logo: fileURL,
      });

    this.setState({ name: "", website: "", subdomain: "", logoImg: "" });
    toast.success("Your settings are saved.", {
      autoClose: 3000,
    });
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (logoImg) =>
        this.setState({
          logoImg,
          updatedLogo: true,
          loading: false,
        })
      );
    }
  };

  render() {
    const { name, website, subdomain, logoImg } = this.state;
    const { company } = this.props;

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <div className="col-sm-9">
        <div className="border">
          <h3 className="mt-2" style={{ fontWeight: 600 }}>
            {company ? company.name : "Add New Project"}
          </h3>

          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <Form>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Project name"
                    value={name}
                    onChange={this.onChangeText}
                  />
                </Form.Group>

                <Form.Group controlId="website">
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="myproject.com"
                    value={website}
                    onChange={this.onChangeText}
                  />
                </Form.Group>
                <Form.Group controlId="subdomain">
                  <Form.Label>
                    Choose a subdomain{" "}
                    <span
                      style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.4)" }}
                    >
                      (only set once)
                    </span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="subdomain"
                      aria-describedby="inputGroupPrepend"
                      value={subdomain}
                      onChange={this.onChangeText}
                      disabled={company ? true : false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="inputGroupPrepend">
                        .backlogs.co
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="logoImg">
                  <Form.Label>Logo</Form.Label>
                  <div className="clearfix">
                    <Upload
                      accept="image/*"
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={this.handleChange}
                    >
                      {logoImg ? (
                        <img
                          src={logoImg}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </div>
                </Form.Group>
              </Form>
              <div className="text-right border-less">
                <div className="col-12">
                  <button
                    className="btn btn-lg btn-primary"
                    onClick={this.onUpdateSettings}
                  >
                    <i className="fas fa-save "></i> Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanySettings;
