// React Basic and Bootstrap
import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./PageError.css";

// import images
import img404 from "../../assets/images/404.png";

class PageError extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { subdomain, company } = this.props;
    return (
      <React.Fragment>
        <section className="bg-errors">
          <div className="headerSpace container"></div>
          <div className="home-center">
            <div className="home-desc-center">
              <div className="container">
                <Row className="justify-content-center">
                  <Col lg={8} md={12} className="text-center">
                    <img
                      src={img404}
                      className="img-fluid"
                      alt=""
                      style={{ width: "420px" }}
                    />
                    <div className="mt-4 text-dark display-3">Oh no!</div>
                    <div className="text-capitalize text-dark mb-4 error-page">
                      {company === null
                        ? "Company " + subdomain + " Not Found"
                        : "Page Not Found"}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <div className="col-md-12 text-center">
                    <a
                      href={company ? "/" : "https://backlogs.co"}
                      className="btn btn-outline-primary mt-lg-5 px-5 py-2"
                      style={{ fontSize: "20px", fontWeight: 700 }}
                    >
                      Go back to homepage
                    </a>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default PageError;
