import React from "react";
import { Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { uuid } from "uuidv4";
import { toast } from "react-toastify";
import ImageUploader from "react-images-upload";
import PulseLoader from "react-spinners/PulseLoader";
import { setUserValue } from "../../redux/action/userAction";
import "./NewFormModal.css";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class UpdatePostStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      photo: "",
      pictures: [],
      details: "",
      prevPostStatus:
        (this.props.post.status && this.props.post.status.toUpperCase()) || "",
      postStatus:
        (this.props.post.status && this.props.post.status.toUpperCase()) || "",
      notifyVoters: true,
      addToChangelog: true,
      name: this.props.post.name || "",
      isSubmitting: false,
    };

    this.onDrop = this.onDrop.bind(this);
  }

  handleModalClose = () => {
    this.setState({
      loading: false,
      photo: "",
      name: "",
      details: "",
      isSubmitting: false,
    });
    this.props.setUserValue({
      showAddNewBugModal: false,
      showAddNewFeatureModal: false,
      showAddNewFeedbackModal: false,
      showUpdatePostStatusModal: false,
      showUpdateFeedbackModal: false,
      showDeleteFeedbackModal: false,
    });
  };

  handleInputChange = (event) => {
    const value =
      event.target.id === "notifyVoters" || event.target.id === "addToChangelog"
        ? event.target.checked
        : event.target.value;
    this.setState({ [event.target.id]: value });
  };

  onDrop(pictureFiles, pictureDataURLs) {
    console.log(pictureFiles[0]);
    this.setState({
      pictures: pictureFiles,
    });
  }

  updatePostStatus = async (event) => {
    this.setState({ isSubmitting: true });
    const {
      prevPostStatus,
      postStatus,
      details,
      photo,
      pictures,
      notifyVoters,
      addToChangelog,
      name,
    } = this.state;
    const { user, post, subdomain } = this.props;
    const { currentUser } = user;
    const subDir =
      post.type === "BUG"
        ? "bug"
        : post.type === "FEATURE"
        ? "feature"
        : "other";

    var fileURL = "";
    if (pictures[0]) {
      var storageRef = await firebase.storage().ref("products");
      var photoName = pictures[0].name;
      var fileRef = storageRef
        .child(subdomain)
        .child("feedback")
        .child(subDir)
        .child(photoName);

      var uploadedFile = await fileRef.put(pictures[0]);
      fileURL = await uploadedFile.ref.getDownloadURL();
    }

    if (postStatus === "COMPLETED") {
      // remove from "active" and add to "completed"
      const postRef = await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(post.id)
        .once("value");
      const postData = postRef.val();

      await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("completed")
        .child(post.id)
        .set({ ...postData, status: postStatus, notify: notifyVoters });

      this.props.history.push({
        pathname: "/post/completed/" + post.id,
        state: { justCompleted: true },
      });

      await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("completed")
        .child(post.id)
        .child("changeLogs")
        .push({
          status: postStatus,
          comment: details,
          photo: fileURL,
          notify: notifyVoters,
          addToChangelog: addToChangelog,
          createdAt: Date.now(),
        });

      await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(post.id)
        .remove();
    } else if (postStatus === "NOT AN ISSUE") {
      // remove from "active" and add to "closed"
      const postRef = await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(post.id)
        .once("value");
      const postData = postRef.val();

      await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("closed")
        .child(post.id)
        .set({ ...postData, status: postStatus, notify: notifyVoters });

      await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("closed")
        .child(post.id)
        .update({ status: postStatus, notify: notifyVoters });

      this.props.history.push("/post/closed/" + post.id);

      await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("closed")
        .child(post.id)
        .child("changeLogs")
        .push({
          status: postStatus,
          comment: details,
          photo: fileURL,
          notify: notifyVoters,
          addToChangelog: addToChangelog,
          createdAt: Date.now(),
        });

      await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(post.id)
        .remove();
    } else {
      // simply update the status in "active"
      await firebase //add to /posts/{companyId}/{postId}
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(post.id)
        .update({ status: postStatus });

      await firebase //add to /posts/{companyId}/{postId}/changelogs
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(post.id)
        .child("changeLogs")
        .push({
          status: postStatus,
          comment: details,
          photo: fileURL,
          notify: notifyVoters,
          addToChangelog: postStatus !== "COMPLETED" ? false : addToChangelog,
          createdAt: Date.now(),
        });
    }

    // -- for prev status
    var countRef = await firebase
      .database()
      .ref("users")
      .child(currentUser.uid)
      .child("companies")
      .child(subdomain)
      .child("stats")
      .child(prevPostStatus.toLowerCase());
    countRef.transaction(function (count) {
      return (count > 0 && count - 1) || 0;
    });

    // ++ for latest status
    countRef = await firebase
      .database()
      .ref("users")
      .child(currentUser.uid)
      .child("companies")
      .child(subdomain)
      .child("stats")
      .child(postStatus.toLowerCase());
    countRef.transaction(function (count) {
      return (count || 0) + 1;
    });

    this.setState({ prevPostStatus: postStatus });

    await firebase //push to /user-posts/{userId}/{requestId}
      .database()
      .ref("user-posts")
      .child(currentUser.uid)
      .child(subdomain)
      .child(post.id)
      .update({
        status: postStatus,
      });

    //add to Changelog if it is set to true
    if (postStatus === "COMPLETED" && addToChangelog) {
      await firebase //push to /user-posts/{userId}/{requestId}
        .database()
        .ref("company-changelogs")
        .child(subdomain)
        .child(post.id)
        .set({
          postId: post.id,
          url: post.id,
          title: name,
          description: details,
          type: post.type,
          photo: fileURL,
          createdAt: Date.now(),
          admin: {
            uid: currentUser.uid,
            avatar: currentUser.avatar,
            name: currentUser.displayName,
            email: currentUser.email || "",
          },
        });
    }

    this.setState({ isSubmitting: false });
    this.props.setUserValue({ showUpdatePostStatusModal: false });
    toast.success("Status updated successfully", {
      autoClose: 4000,
    });
  };

  handleChangePostStatus = (event) => {
    this.setState({ postStatus: event.target.value.toUpperCase() });
  };

  render() {
    const { user } = this.props;
    const { showUpdatePostStatusModal } = user;
    const {
      photo,
      postStatus,
      notifyVoters,
      addToChangelog,
      name,
      isSubmitting,
    } = this.state;

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Modal
        show={showUpdatePostStatusModal}
        onHide={this.handleModalClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update the status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                defaultValue={
                  postStatus[0].toUpperCase() +
                  postStatus.slice(1).toLowerCase()
                }
                onChange={this.handleChangePostStatus}
              >
                <option>Pending</option>
                <option>Planned</option>
                <option>Ongoing</option>
                <option>Completed</option>
                <option>Not an issue</option>
              </Form.Control>
            </Form.Group>

            {postStatus !== "COMPLETED" && (
              <Form.Group controlId="details">
                <Form.Label>
                  Comments{" "}
                  <span
                    style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.4)" }}
                  >
                    (optional)
                  </span>
                </Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows="7"
                  placeholder="Any additional comments"
                  value={this.state.details}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
            )}

            {postStatus !== "COMPLETED" && (
              <ImageUploader
                withIcon={true}
                buttonText="Choose an image"
                label="Max file size: 20MB, accepted: jpg, gif, png"
                onChange={this.onDrop}
                imgExtension={[".jpg", ".gif", ".png"]}
                maxFileSize={20971520}
                singleImage={true}
                withPreview={true}
              />
            )}
            <Form.Group controlId="notifyVoters">
              <Form.Check
                type="switch"
                id="notifyVoters"
                label="Notify all voters?"
                checked={notifyVoters}
                onChange={this.handleInputChange}
              />
            </Form.Group>
            {postStatus === "COMPLETED" && (
              <>
                <Form.Group controlId="addToChangelog">
                  <Form.Check
                    type="switch"
                    id="addToChangelog"
                    label="Add to changelog page?"
                    checked={addToChangelog}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                {addToChangelog && (
                  <>
                    <Form.Group
                      controlId="name"
                      style={{ marginTop: 0, paddingTop: 0 }}
                    >
                      <Form.Label>Changelog Title</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={name}
                        onChange={this.handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="details">
                      <Form.Label>Changelog Description </Form.Label>
                      <Form.Control
                        required
                        as="textarea"
                        rows="7"
                        placeholder="Tell your user a bit details"
                        value={this.state.details}
                        onChange={this.handleInputChange}
                      />
                      <div className="markdown-section">
                        <Form.Label
                          className="text-muted"
                          style={{ fontSize: "12px" }}
                        >
                          <i class="fab fa-markdown"></i> Markdown is supported.
                          (
                          <a
                            href="https://www.markdownguide.org/cheat-sheet/"
                            target="_blank"
                          >
                            cheatsheet
                          </a>
                          )
                        </Form.Label>
                      </div>
                    </Form.Group>
                    <ImageUploader
                      withIcon={true}
                      buttonText="Choose an image"
                      label="Max file size: 20MB, accepted: jpg, gif, png"
                      onChange={this.onDrop}
                      imgExtension={[".jpg", ".gif", ".png"]}
                      maxFileSize={20971520}
                      singleImage={true}
                      withPreview={true}
                    />
                  </>
                )}
              </>
            )}
          </Form>
          <button
            onClick={this.updatePostStatus}
            className="btn btn-primary px-5 mt-3"
            disabled={isSubmitting ? true : false}
          >
            {isSubmitting ? (
              <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UpdatePostStatus);
