import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Layout Components
import Topbar from "./Topbar";
import Footer from "./Footer";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { company } = this.props;
    return (
      <React.Fragment>
        <Topbar company={company} />
        {this.props.children}
        {/* <Footer /> */}
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
