export const objectToArray = object => {
  if (object) {
    return Object.entries(object).map(e => Object.assign(e[1], { id: e[0] }));
  }
};

export const createCommentsTree = comments => {
  let hashTable = Object.create(null);
  comments.reverse().forEach(a => (hashTable[a.id] = { ...a, childNodes: [] }));
  let commentsTree = [];
  comments.forEach(a => {
    if (a.parentId) hashTable[a.parentId].childNodes.push(hashTable[a.id]);
    else commentsTree.push(hashTable[a.id]);
  });
  return commentsTree;
};
