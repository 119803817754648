import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import Favicon from "react-favicon";
import PulseLoader from "react-spinners/PulseLoader";
import firebase from "firebase/app";
import "firebase/database";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/layout/Layout";
import Posts from "./pages/Posts/Posts";
import PostDetails from "./pages/PostDetails/PostDetails";
import Roadmap from "./pages/Roadmap/Roadmap";
import ChangeLog from "./pages/ChangeLog/ChangeLog";
import ChangelogDetails from "./pages/ChangeLog/ChangelogDetails";
import PageError from "./pages/PageError/PageError";
import Profile from "./pages/Profile/Profile";
import Settings from "./pages/Settings/Settings";
import Login from "./pages/Login/Login";
import "./App.css";

toast.configure();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentURL: this.props.currentURL,
      company: null,
      companyExists: null,
      subdomain: "",
    };
  }

  componentDidMount = async () => {
    const { currentURL } = this.state;
    var subdomain = "";

    if (
      currentURL.includes("backlogs.co") ||
      currentURL.includes("localhost")
    ) {
      // non-custom domain
      var parsedURL = currentURL.split(".");
      subdomain = parsedURL[0];
    } else {
      // custom domain
      var newURL = currentURL.replace(/\./g, "-");
      const domainRef = await firebase
        .database()
        .ref("custom-domains")
        .child(newURL)
        .once("value");

      subdomain = domainRef.val().subdomain;
    }

    if (subdomain) {
      const companyRef = await firebase
        .database()
        .ref("subdomains")
        .child(subdomain)
        .once("value");
      this.setState({
        subdomain: subdomain,
        company: companyRef.val(),
        companyExists: companyRef.exists(),
      });
    }
  };
  render() {
    const { subdomain, company, companyExists } = this.state;

    if ((!subdomain || !company) && companyExists === null) {
      return (
        <div className="text-center" style={{ marginTop: 150 }}>
          <PulseLoader sizeUnit={"px"} size={8} color={"#66b0ff"} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Favicon
          url={
            company && (company.customDomain !== "" || company.whiteLabel)
              ? company.logo
              : "https://backlogs.co/favicon.ico"
          }
        />
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              component={(props) => (
                <Layout company={company}>
                  <Roadmap
                    {...props}
                    subdomain={subdomain}
                    company={company}
                    companyExists={companyExists}
                  />
                </Layout>
              )}
            />
            <Route
              exact
              path="/post"
              component={(props) => (
                <Layout company={company}>
                  <Posts
                    {...props}
                    subdomain={subdomain}
                    company={company}
                    companyExists={companyExists}
                  />
                </Layout>
              )}
            />
            <Route
              exact
              path="/roadmap"
              component={(props) => (
                <Layout company={company}>
                  <Roadmap
                    {...props}
                    subdomain={subdomain}
                    company={company}
                    companyExists={companyExists}
                  />
                </Layout>
              )}
            />
            <Route
              exact
              path="/changelog"
              component={(props) => (
                <Layout company={company}>
                  <ChangeLog
                    {...props}
                    subdomain={subdomain}
                    company={company}
                    companyExists={companyExists}
                  />
                </Layout>
              )}
            />
            <Route
              exact
              path="/changelog/:changelogId"
              component={(props) => (
                <Layout company={company}>
                  <ChangelogDetails
                    {...props}
                    subdomain={subdomain}
                    company={company}
                    companyExists={companyExists}
                  />
                </Layout>
              )}
            />
            <Route
              exact
              path="/post/completed/:postId"
              component={(props) => (
                <Layout company={company}>
                  <PostDetails
                    {...props}
                    subdomain={subdomain}
                    company={company}
                    companyExists={companyExists}
                    postStatus={"COMPLETED"}
                  />
                </Layout>
              )}
            />
            <Route
              exact
              path="/post/closed/:postId"
              component={(props) => (
                <Layout company={company}>
                  <PostDetails
                    {...props}
                    subdomain={subdomain}
                    company={company}
                    companyExists={companyExists}
                    postStatus={"NOT AN ISSUE"}
                  />
                </Layout>
              )}
            />
            <Route
              exact
              path="/post/:postId"
              component={(props) => (
                <Layout company={company}>
                  <PostDetails
                    {...props}
                    subdomain={subdomain}
                    company={company}
                    companyExists={companyExists}
                  />
                </Layout>
              )}
            />
            <Route
              exact
              path="/profile"
              component={(props) => (
                <Layout company={company}>
                  <Profile
                    {...props}
                    subdomain={subdomain}
                    company={company}
                    companyExists={companyExists}
                  />
                </Layout>
              )}
            />
            <Route
              exact
              path="/settings"
              component={(props) => (
                <Layout company={company}>
                  <Settings
                    {...props}
                    subdomain={subdomain}
                    company={company}
                    companyExists={companyExists}
                  />
                </Layout>
              )}
            />
            <Route
              exact
              path="/login"
              component={(props) => (
                <Layout>
                  <Login {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/error"
              component={(props) => (
                <Layout company={company}>
                  <PageError
                    {...props}
                    subdomain={subdomain}
                    company={company}
                    companyExists={companyExists}
                  />
                </Layout>
              )}
            />
            <Route
              component={(props) => (
                <Layout company={company}>
                  <PageError
                    {...props}
                    subdomain={subdomain}
                    company={company}
                    companyExists={companyExists}
                  />
                </Layout>
              )}
            />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
