import React from "react";
import { Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { uuid } from "uuidv4";
import { toast } from "react-toastify";
import ImageUploader from "react-images-upload";
import PulseLoader from "react-spinners/PulseLoader";
import { setUserValue } from "../../redux/action/userAction";
import "./NewFormModal.css";

class UpdateFeedbackModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      post: this.props.post,
      photo: "",
      pictures: [],
      name: "",
      details: "",
      isSubmitting: false,
    };

    this.onDrop = this.onDrop.bind(this);
    this.handleLaunchChange = this.handleLaunchChange.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.post !== prevProps.post) {
      this.setState({
        post: this.props.post,
        name: this.props.post.name,
        details: this.props.post.details,
        photo: this.props.post.photo || "",
      });
    }
  };

  handleLaunchChange(isLaunched) {
    this.setState({ isLaunched });
  }

  handleModalClose = () => {
    this.setState({
      loading: false,
      photo: "",
      name: "",
      details: "",
      isSubmitting: false,
    });
    this.props.setUserValue({ showUpdateFeedbackModal: false });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: pictureFiles,
    });
  }

  submitUpdate = async (event) => {
    this.setState({ isSubmitting: true });
    const { name, details, photo, pictures } = this.state;
    const { subdomain, post } = this.props;

    if (name === "" || details === "") {
      toast.error("Oops, Name and Details cannot be empty.", {
        autoClose: 4000,
      });
      this.setState({ isSubmitting: false });
      return;
    }

    var fileURL = photo;
    if (pictures[0]) {
      var storageRef = await firebase.storage().ref("products");
      var photoName = pictures[0].name;
      var fileRef = storageRef
        .child(subdomain)
        .child("feedback")
        .child("bug")
        .child(photoName);

      var uploadedFile = await fileRef.put(pictures[0]);
      fileURL = await uploadedFile.ref.getDownloadURL();
    }

    const updateRequest = {
      name: name,
      details: details,
      photo: fileURL,
    };

    var status = "active";
    if (post.status === "COMPLETED") {
      status = "completed";
    }
    if (post.status === "NOT AN ISSUE") {
      status = "closed";
    }

    await firebase //add to /posts/{companyId}/{postId}
      .database()
      .ref("company-posts")
      .child(subdomain)
      .child(status)
      .child(post.id)
      .update(updateRequest);

    this.setState({ isSubmitting: false });
    this.props.setUserValue({
      showUpdateFeedbackModal: false,
      showAddNewFeatureModal: false,
      showAddNewFeedbackModal: false,
      showUpdatePostStatusModal: false,
      showDeleteFeedbackModal: false,
    });

    toast.success("Feedback updated successfully", {
      autoClose: 4000,
    });
  };

  render() {
    const { showUpdateFeedbackModal } = this.props.user;
    const { isSubmitting } = this.state;

    return (
      <Modal
        show={showUpdateFeedbackModal}
        onHide={this.handleModalClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update your feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="A short title"
                value={this.state.name}
                onChange={this.handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="details">
              <Form.Label>Details</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows="7"
                placeholder="Any additional details"
                value={this.state.details}
                onChange={this.handleInputChange}
              />
              <div className="markdown-section">
                <Form.Label className="text-muted" style={{ fontSize: "12px" }}>
                  <i class="fab fa-markdown"></i> Markdown is supported. (
                  <a
                    href="https://www.markdownguide.org/cheat-sheet/"
                    target="_blank"
                  >
                    cheatsheet
                  </a>
                  )
                </Form.Label>
              </div>
            </Form.Group>

            <ImageUploader
              withIcon={true}
              buttonText="Choose an image"
              label="Max file size: 20MB, accepted: jpg, gif, png"
              onChange={this.onDrop}
              imgExtension={[".jpg", ".gif", ".png"]}
              maxFileSize={20971520}
              singleImage={true}
              withPreview={true}
            />
          </Form>
          <button
            onClick={this.submitUpdate}
            className="btn btn-primary px-5 my-1"
            disabled={isSubmitting ? true : false}
          >
            {isSubmitting ? (
              <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UpdateFeedbackModal);
