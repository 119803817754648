/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { withRouter } from "react-router";
import { Button, Modal } from "react-bootstrap";
import Avatar from "react-avatar-edit";
import ProfileSettings from "./ProfileSettings";
import "./Profile.css";

var unsubscribe;
var currentUserListener;
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkProfile: true,
      currentUser: null,
      showChangeAvatarModal: false,
      preview: null,
      avatarSrc: null,
      updatedAvatar: "",
    };

    this.onAvatarCrop = this.onAvatarCrop.bind(this);
    this.onAvatarClose = this.onAvatarClose.bind(this);
  }

  componentDidMount = async () => {
    const { subdomain } = this.props;
    if (subdomain) {
      const companyRef = await firebase
        .database()
        .ref("subdomains")
        .child(subdomain)
        .once("value");
      if (!companyRef.exists()) {
        this.props.history.push("/error");
        return;
      }
    }

    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        currentUserListener = await firebase
          .database()
          .ref("users")
          .child(user.uid)
          .on("value", (snapshot) => {
            this.setState({ currentUser: snapshot.val() });
          });
      } else {
        this.props.history.push("/");
      }
    });
  };

  componentWillUnmount = async () => {
    this.state.currentUser &&
      (await firebase
        .database()
        .ref("users")
        .child(this.state.currentUser.uid)
        .off("value", currentUserListener));
    unsubscribe && unsubscribe();
  };

  handleModalClose = () => {
    this.setState({ showChangeAvatarModal: false });
  };

  onShowChangeAvatarModal = () => {
    this.setState({ showChangeAvatarModal: true });
  };

  onAvatarClose() {
    this.setState({ preview: null });
  }

  onAvatarCrop(preview) {
    // console.log("crop preview: ", preview);
    this.setState({ preview });
  }

  onImageLoad(image) {
    // console.log("image: ", image.target.files[0]);
  }

  handleUploadImage = async () => {
    const { currentUser, preview } = this.state;
    var storageRef = await firebase.storage().ref(currentUser.uid);
    var avatarName = "avatar" + Date();
    var fileRef = storageRef
      .child("account")
      .child("personal")
      .child(avatarName);
    var type = preview.split(";")[0].split(":")[1];

    var uploadedFile = await fileRef.putString(
      preview.split(",")[1],
      "base64",
      {
        contentType: type,
      }
    );
    var fileURL = await uploadedFile.ref.getDownloadURL();
    await firebase
      .database()
      .ref("users")
      .child(currentUser.uid)
      .update({ avatar: fileURL });
    this.setState({ updatedAvatar: fileURL, showChangeAvatarModal: false });
  };

  render() {
    const { currentUser, showChangeAvatarModal, updatedAvatar } = this.state;

    if (currentUser === null) {
      return null;
    }

    return (
      <Fragment>
        <Modal
          show={showChangeAvatarModal}
          onHide={this.handleModalClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Update your avatar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Avatar
              width={"100%"}
              height={300}
              onCrop={this.onAvatarCrop}
              onClose={this.onAvatarClose}
              src={this.state.avatarSrc}
              label="Choose a photo"
              onImageLoad={this.onImageLoad}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary px-5" onClick={this.handleUploadImage}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <main className="bg-light profile-container">
          <div className="">
            <div className="headerSpace container"></div>
            <div className="container mt-5 px-5">
              <h3 className="title"></h3>
              <div className="row mtop-minus">
                {/* Left column navigator */}
                <div className="col-sm-3 user_col">
                  <div className="text-center">
                    <img
                      src={
                        updatedAvatar === ""
                          ? currentUser.avatar
                          : updatedAvatar
                      }
                      className="avatar img-circle img-thumbnail"
                      alt="avatar"
                      onClick={this.onShowChangeAvatarModal}
                    />
                  </div>
                  <div className="text-left">
                    <h4>Change Avatar</h4>
                    <p>Max size 2MB, ratio 1:1, png/jpeg only</p>
                    <Link onClick={this.onShowChangeAvatarModal}>Edit</Link>
                  </div>
                </div>
                {/* End left column navigator */}

                <ProfileSettings currentUser={currentUser} />
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}

export default withRouter(Profile);
