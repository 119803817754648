import React from "react";
import { format } from "date-fns";
import { withRouter } from "react-router";
import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { compose } from "redux";

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { card, index, user, subdomain } = this.props;
    const { currentUser } = user;
    if (!card) return null;

    return (
      <Draggable
        draggableId={card.id}
        index={index}
        isDragDisabled={
          currentUser &&
          currentUser.companies &&
          currentUser.companies[subdomain]
            ? false
            : true
        }
      >
        {(provided, snapshot) => {
          const style = {
            backgroundColor: snapshot.isDragging
              ? card.isDraggableColor
              : "white",
            border: snapshot.isDragging ? "2px solid #758184" : "none",
            transition: "background-color .2s ease",
            ...provided.draggableProps.style,
          };
          return (
            <div
              className="mb-3 bg-white card board-item"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={style}
            >
              <div
                className="p-3 card-body"
                onClick={() => this.props.history.push("/post/" + card.id)}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="left">
                    <div className="circle mr-2">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="circle"
                        className="svg-inline--fa fa-circle fa-w-16 chat-online"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill={card.color}
                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                        ></path>
                      </svg>
                    </div>
                    <div className="post-title">{card.name}</div>
                  </div>
                  <div className="right ml-auto">
                    <img src={card.submitter.avatar} alt="" />
                    <span className="count ml-2">{card.voteCount}</span>
                  </div>
                </div>
                <button className="board-btn badge badge-secondary badge-pill my-2">
                  {card.type}
                </button>
                <p className="card-text">
                  <small className="text-muted">
                    {format(card.createdAt, "PP")}
                  </small>
                </p>
              </div>
            </div>
          );
        }}
      </Draggable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

export default compose(withRouter, connect(mapStateToProps))(Card);
