import React from "react";
import { Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { uuid } from "uuidv4";
import { toast } from "react-toastify";
import ImageUploader from "react-images-upload";
import PulseLoader from "react-spinners/PulseLoader";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { setUserValue } from "../../redux/action/userAction";
import "./NewFormModal.css";

class UpdateChangelogModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      changelog: this.props.changelog,
      photo: this.props.changelog.photo || "",
      pictures: [],
      name: this.props.changelog.title,
      type: this.props.changelog.type,
      details: this.props.changelog.description,
      isSubmitting: false,
    };

    this.onDrop = this.onDrop.bind(this);
  }

  handleModalClose = () => {
    this.setState({
      loading: false,
      photo: "",
      name: "",
      details: "",
      isSubmitting: false,
    });
    this.props.setUserValue({ showUpdateChangelogModal: false });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleDetailsChange = (details) => {
    this.setState({ details: details });
  };

  handleSelectType = (event) => {
    this.setState({ type: event.target.value });
  };

  onDrop(pictureFiles, pictureDataURLs) {
    console.log(pictureFiles[0]);
    this.setState({
      pictures: pictureFiles,
    });
  }

  updateChangelog = async (event) => {
    this.setState({ isSubmitting: true });
    const { name, details, photo, pictures, type } = this.state;
    const { user, subdomain, changelog } = this.props;

    if (name === "" || details === "") {
      toast.error("Oops, Title and Details cannot be empty.", {
        autoClose: 4000,
      });
      this.setState({ isSubmitting: false });
      return;
    }

    var fileURL = photo;
    if (pictures[0]) {
      var storageRef = await firebase.storage().ref("products");
      var photoName = pictures[0].name;
      var fileRef = storageRef
        .child(subdomain)
        .child("feedback")
        .child("bug")
        .child(photoName);

      var uploadedFile = await fileRef.put(pictures[0]);
      fileURL = await uploadedFile.ref.getDownloadURL();
    }

    await firebase //push to /user-posts/{userId}/{requestId}
      .database()
      .ref("company-changelogs")
      .child(subdomain)
      .child(changelog.url)
      .update({
        title: name,
        description: details,
        type: type,
        photo: fileURL,
      });

    this.setState({ isSubmitting: false });
    this.props.setUserValue({
      showUpdateChangelogModal: false,
    });

    toast.success("Changelog updated successfully", {
      autoClose: 4000,
    });
  };

  render() {
    const { showUpdateChangelogModal } = this.props.user;
    const { isSubmitting, name, details, type } = this.state;

    const types = [
      {
        key: "BUG",
        text: "🐞 Bug",
      },
      {
        key: "FEATURE",
        text: "🎉 Feature",
      },
      {
        key: "OTHER",
        text: "🤟Other",
      },
    ];

    return (
      <Modal
        show={showUpdateChangelogModal}
        onHide={this.handleModalClose}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add a new changelog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Changelog title"
                value={name}
                onChange={this.handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="category">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                value={type}
                onChange={this.handleSelectType}
              >
                <option value="">Choose...</option>
                {types.map((type) => {
                  return (
                    <option key={type.key} value={type.key}>
                      {type.text}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="details">
              <Form.Label>Details</Form.Label>
              <SimpleMDE
                id="details"
                value={details}
                onChange={this.handleDetailsChange}
                options={{
                  autofocus: false,
                  spellChecker: false,
                  status: false,
                  onToggleFullScreen: false,
                  toolbar: [
                    "bold",
                    "italic",
                    "heading",
                    "|",
                    "quote",
                    "unordered-list",
                    "ordered-list",
                    "|",
                    "code",
                    "link",
                    "image",
                    "|",
                    "preview",
                  ],
                  renderingConfig: {
                    codeSyntaxHighlighting: true,
                  },
                }}
              />
              <div className="markdown-section">
                <div className="left">
                  <Form.Label
                    className="text-muted"
                    style={{ fontSize: "12px" }}
                  >
                    <i className="fab fa-markdown"></i> Markdown is supported. (
                    <a
                      href="https://www.markdownguide.org/cheat-sheet/"
                      target="_blank"
                    >
                      cheatsheet
                    </a>
                    )
                  </Form.Label>
                </div>
              </div>
            </Form.Group>

            <ImageUploader
              withIcon={true}
              buttonText="Choose an image"
              label="Max file size: 20MB, accepted: jpg, gif, png"
              onChange={this.onDrop}
              imgExtension={[".jpg", ".gif", ".png"]}
              maxFileSize={20971520}
              singleImage={true}
              withPreview={true}
            />
          </Form>
          <button
            onClick={this.updateChangelog}
            className="btn btn-primary px-5 my-1"
            disabled={isSubmitting ? true : false}
          >
            {isSubmitting ? (
              <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UpdateChangelogModal);
