import React from "react";
import { Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { setUserValue } from "../../redux/action/userAction";
import "./NewFormModal.css";

class DeleteFeedbackModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isSubmitting: false,
    };
  }

  handleModalClose = () => {
    this.setState({
      loading: false,
      isSubmitting: false,
    });
    this.props.setUserValue({ showDeleteFeedbackModal: false });
  };

  onDeleteFeedback = async (event) => {
    this.setState({ isSubmitting: true });
    const { subdomain, post } = this.props;

    var status = "active";
    if (post.status === "COMPLETED") {
      status = "completed";
    }
    if (post.status === "NOT AN ISSUE") {
      status = "closed";
    }

    await firebase //add to /posts/{companyId}/{postId}
      .database()
      .ref("company-posts")
      .child(subdomain)
      .child(status)
      .child(post.id)
      .remove();

    this.setState({ isSubmitting: false });
    this.props.setUserValue({
      showDeleteFeedbackModal: false,
      showAddNewFeatureModal: false,
      showAddNewFeedbackModal: false,
      showUpdatePostStatusModal: false,
      showDeleteFeedbackModal: false,
    });

    toast.success("Feedback deleted successfully", {
      autoClose: 4000,
    });

    this.props.history.push("/post");
  };

  render() {
    const { showDeleteFeedbackModal } = this.props.user;
    const { isSubmitting } = this.state;

    return (
      <Modal
        show={showDeleteFeedbackModal}
        onHide={this.handleModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete your feedback?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6 text-center">
              <button
                className=" btn btn-primary px-5 my-1"
                onClick={() => {
                  this.onDeleteFeedback();
                }}
              >
                {isSubmitting ? (
                  <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                ) : (
                  "Delete it"
                )}
              </button>
            </div>
            <div className="col-6 text-center">
              <button
                className="btn btn-secondary px-5"
                onClick={() => this.handleModalClose()}
              >
                Go back
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DeleteFeedbackModal);
