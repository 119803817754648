import React from "react";
import { Dropdown } from "semantic-ui-react";
import firebase from "firebase/app";
import "firebase/database";
import PulseLoader from "react-spinners/PulseLoader";
import PostItem from "../PostItem/PostItem";
import "./PostList.css";
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router";
import no_post_img from "../../assets/images/no-posts.png";

class PostList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allPostsByTime: [],
      allPostsByVote: [],
      filterBy: "newest",
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    const { subdomain, companyExists } = this.props;
    if (!companyExists) {
      this.props.history.push("/error");
      return;
    }

    const allPostsByTimeRef = await firebase
      .database()
      .ref("company-posts")
      .child(subdomain)
      .child("active")
      .orderByChild("createdAt")
      .once("value");

    if (
      allPostsByTimeRef.val() === null ||
      allPostsByTimeRef.val() === undefined
    ) {
      this.setState({ isLoading: false });
      return;
    }

    var arrayOfPosts = [];
    allPostsByTimeRef.forEach((post) => {
      arrayOfPosts.push(post.val());
    });
    this.setState({
      allPostsByTime: arrayOfPosts.reverse(),
    });

    const allPostsByVoteRef = await firebase
      .database()
      .ref("company-posts")
      .child(subdomain)
      .child("active")
      .orderByChild("voteCount")
      .once("value");

    arrayOfPosts = [];
    allPostsByVoteRef.forEach((post) => {
      arrayOfPosts.push(post.val());
    });
    this.setState({
      allPostsByVote: arrayOfPosts.reverse(),
    });

    this.setState({ isLoading: false });
  };

  onFilterChange = async (event, data) => {
    this.setState({ filterBy: data.value });
  };

  render() {
    const { allPostsByTime, allPostsByVote, filterBy, isLoading } = this.state;

    var allPosts = filterBy === "newest" ? allPostsByTime : allPostsByVote;

    if (isLoading) {
      return (
        <div className="text-center" style={{ marginTop: 50 }}>
          <PulseLoader sizeUnit={"px"} size={8} color={"#66b0ff"} />
        </div>
      );
    }

    if (!isLoading && allPostsByTime.length === 0) {
      return (
        <div className="container">
          <Row className="justify-content-center">
            <Col lg={8} md={12} className="text-center">
              <img
                src={no_post_img}
                className="img-fluid"
                alt=""
                style={{ width: "420px" }}
              />
              <div className="text-capitalize text-dark mb-4 error-page">
                No posts found.
              </div>
            </Col>
          </Row>
        </div>
      );
    }

    const friendOptions = [
      {
        key: "newest",
        text: "newest",
        value: "newest",
      },
      {
        key: "upvotes",
        text: "upvotes",
        value: "upvotes",
      },
    ];

    return (
      <div className="post-list">
        <span
          style={{
            paddingRight: "0.8em",
            marginBottom: "10px",
            float: "right",
          }}
        >
          Sort by{" "}
          <Dropdown
            inline
            options={friendOptions}
            value={filterBy}
            onChange={this.onFilterChange}
          />
        </span>
        {allPosts.map(
          (post) =>
            post.status !== "COMPLETED" &&
            post.status !== "NOT AN ISSUE" && (
              <PostItem key={post.id} post={post} />
            )
        )}
      </div>
    );
  }
}
export default withRouter(PostList);
