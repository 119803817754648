import React from "react";
import { Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { uuid } from "uuidv4";
import { toast } from "react-toastify";
import ImageUploader from "react-images-upload";
import PulseLoader from "react-spinners/PulseLoader";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { setUserValue } from "../../redux/action/userAction";
import "./NewFormModal.css";

class AddNewChangelog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      photo: "",
      pictures: [],
      name: "",
      type: "",
      details: "",
      isSubmitting: false,
    };

    this.onDrop = this.onDrop.bind(this);
    this.handleLaunchChange = this.handleLaunchChange.bind(this);
  }

  handleLaunchChange(isLaunched) {
    this.setState({ isLaunched });
  }

  handleModalClose = () => {
    this.setState({
      loading: false,
      photo: "",
      name: "",
      details: "",
      type: "",
      isSubmitting: false,
    });
    this.props.setUserValue({ showAddNewChangelogModal: false });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleDetailsChange = (details) => {
    this.setState({ details: details });
  };

  handleSelectType = (event) => {
    this.setState({ type: event.target.value });
  };

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: pictureFiles,
    });
  }

  submitNewChangelog = async (event) => {
    this.setState({ isSubmitting: true });
    const { name, details, pictures, type } = this.state;
    const { user, subdomain } = this.props;
    const { currentUser } = user;

    if (name === "" || details === "" || type === "") {
      toast.error("Oops, Title, Type and Details cannot be empty.", {
        autoClose: 4000,
      });
      this.setState({ isSubmitting: false });
      return;
    }

    var newName = "";
    var tmpName = "";

    newName = name
      .toLowerCase()
      // eslint-disable-next-line no-useless-escape
      .replace(/([~!@#$%^&*()_+=`{}\[\]\|\\:;'<>,.\/? ])+/g, "-")
      .replace(/^(-)+|(-)+$/g, "");

    var requestRef = null;
    do {
      tmpName = newName + "-" + uuid().split("-")[0];
      // eslint-disable-next-line no-await-in-loop
      requestRef = await firebase
        .database()
        .ref("company-changelogs")
        .child(subdomain)
        .child(tmpName)
        .once("value");
    } while (requestRef && requestRef.exists() === true);
    newName = tmpName;

    const color = `hsl(${Math.floor(Math.random() * 360)}, 100%, 93%)`;

    var fileURL = "";
    if (pictures[0]) {
      var storageRef = await firebase.storage().ref("products");
      var photoName = pictures[0].name;
      var fileRef = storageRef
        .child(subdomain)
        .child("feedback")
        .child("bug")
        .child(photoName);

      var uploadedFile = await fileRef.put(pictures[0]);
      fileURL = await uploadedFile.ref.getDownloadURL();
    }

    await firebase //push to /user-posts/{userId}/{requestId}
      .database()
      .ref("company-changelogs")
      .child(subdomain)
      .child(newName)
      .set({
        postId: newName,
        url: newName,
        title: name,
        description: details,
        type: type,
        photo: fileURL,
        createdAt: Date.now(),
        admin: {
          uid: currentUser.uid,
          avatar: currentUser.avatar,
          name: currentUser.displayName,
          email: currentUser.email || "",
        },
      });

    this.setState({ isSubmitting: false });
    this.props.setUserValue({
      showAddNewChangelogModal: false,
    });

    toast.success("Changelog added successfully", {
      autoClose: 4000,
    });

    this.props.history.push("/changelog/" + newName);
  };

  render() {
    const { showAddNewChangelogModal } = this.props.user;
    const { isSubmitting, type } = this.state;

    const types = [
      {
        key: "BUG",
        text: "🐞 Bug",
      },
      {
        key: "FEATURE",
        text: "🎉 Feature",
      },
      {
        key: "OTHER",
        text: "🤟Other",
      },
    ];

    return (
      <Modal
        show={showAddNewChangelogModal}
        onHide={this.handleModalClose}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add a new changelog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Changelog title"
                value={this.state.name}
                onChange={this.handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="category">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                value={type}
                onChange={this.handleSelectType}
              >
                <option value="">Choose...</option>
                {types.map((type) => {
                  return (
                    <option key={type.key} value={type.key}>
                      {type.text}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="details">
              <Form.Label>Details</Form.Label>
              <SimpleMDE
                id="details"
                value={this.state.details}
                onChange={this.handleDetailsChange}
                options={{
                  autofocus: false,
                  spellChecker: false,
                  status: false,
                  onToggleFullScreen: false,
                  toolbar: [
                    "bold",
                    "italic",
                    "heading",
                    "|",
                    "quote",
                    "unordered-list",
                    "ordered-list",
                    "|",
                    "code",
                    "link",
                    "image",
                    "|",
                    "preview",
                  ],
                  renderingConfig: {
                    codeSyntaxHighlighting: true,
                  },
                }}
              />
              <div className="markdown-section">
                <div className="left">
                  <Form.Label
                    className="text-muted"
                    style={{ fontSize: "12px" }}
                  >
                    <i className="fab fa-markdown"></i> Markdown is supported. (
                    <a
                      href="https://www.markdownguide.org/cheat-sheet/"
                      target="_blank"
                    >
                      cheatsheet
                    </a>
                    )
                  </Form.Label>
                </div>
              </div>
            </Form.Group>

            <ImageUploader
              withIcon={true}
              buttonText="Choose an image"
              label="Max file size: 20MB, accepted: jpg, gif, png"
              onChange={this.onDrop}
              imgExtension={[".jpg", ".gif", ".png"]}
              maxFileSize={20971520}
              singleImage={true}
              withPreview={true}
            />
          </Form>
          <button
            onClick={this.submitNewChangelog}
            className="btn btn-primary px-5 my-1"
            disabled={isSubmitting ? true : false}
          >
            {isSubmitting ? (
              <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AddNewChangelog);
