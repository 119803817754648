import React from "react";
import { formatDistance } from "date-fns";
import { withRouter } from "react-router";
import ReactMarkdown from "react-markdown";
import Linkify from "linkifyjs/react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import CodeBlock from "../../components/utils/CodeBlock";

class ChangeLogItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageOpen: false,
    };
  }

  goToChangelogDetails = (changelog) => {
    this.props.history.push("/changelog/" + changelog.url);
  };

  render() {
    const { imageOpen } = this.state;
    const { changelog } = this.props;
    const { title, description, type, photo, postId, createdAt } = changelog;
    var tmpDate = formatDistance(createdAt, Date.now());
    const newDateFormat =
      tmpDate.split(" ")[0] === "about"
        ? tmpDate.replace("about", "")
        : tmpDate;
    const typeColor = type === "BUG" ? "danger" : "success";

    var newType = "";
    if (type === "BUG") {
      newType = "Bug";
    } else if (type === "FEATURE") {
      newType = "Feature";
    } else {
      newType = "Other";
    }

    return (
      <>
        <article className="article" id={changelog.createdAt}>
          <div className="titleDiv">
            <div className="left">
              <h2
                className="log-title"
                onClick={() => this.goToChangelogDetails(changelog)}
              >
                {title}
              </h2>
            </div>
            <div className="right">
              <time className="article-date" dateTime={createdAt}>
                <span className="date-published">{newDateFormat} ago</span>
              </time>
            </div>
          </div>
          <div className="article-meta">
            <div key={type} className={`tag mr-2 bg-${typeColor}`}>
              {newType}
            </div>
          </div>
          <div className="article-content">
            <Linkify style={{ whiteSpace: "pre-line" }}>
              <ReactMarkdown
                source={description}
                renderers={{ code: CodeBlock }}
              />
            </Linkify>
            {photo && (
              <figure className="article-figure mt-2">
                <img
                  src={photo}
                  className="figure-img img-fluid"
                  alt={title}
                  style={{
                    cursor: "pointer",
                    maxHeight: "400px",
                    width: "auto",
                  }}
                  onClick={() => this.setState({ imageOpen: true })}
                />
              </figure>
            )}
            {imageOpen && (
              <Lightbox
                mainSrc={photo}
                onCloseRequest={() => this.setState({ imageOpen: false })}
              />
            )}
          </div>
        </article>
      </>
    );
  }
}

export default withRouter(ChangeLogItem);
