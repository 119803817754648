/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { Fragment } from "react";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { withRouter } from "react-router";
import { ListGroup } from "react-bootstrap";
import CompanySettings from "./CompanySettings";
import "./Settings.css";

var unsubscribe;
var currentUserListener;

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      allCompanies: [],
      company: null,
    };
  }

  componentDidMount = async () => {
    const { subdomain } = this.props;
    if (subdomain) {
      const companyRef = await firebase
        .database()
        .ref("subdomains")
        .child(subdomain)
        .once("value");
      if (!companyRef.exists()) {
        this.props.history.push("/error");
        return;
      }
    }

    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        currentUserListener = await firebase
          .database()
          .ref("users")
          .child(user.uid)
          .on("value", (snapshot) => {
            this.setState({
              currentUser: snapshot.val(),
              allCompanies: Object.values(snapshot.val().companies) || [],
            });
          });
      } else {
        this.props.history.push("/");
      }
    });
  };

  componentWillUnmount = async () => {
    this.state.currentUser &&
      (await firebase
        .database()
        .ref("users")
        .child(this.state.currentUser.uid)
        .off("value", currentUserListener));
    unsubscribe && unsubscribe();
  };

  newFormToAddNewCompany = () => {
    this.setState({ company: null });
  };

  render() {
    const { currentUser, allCompanies, company } = this.state;

    if (currentUser === null) {
      return null;
    }

    return (
      <Fragment>
        <main className="bg-light settings-container">
          <div className="headerSpace container"></div>
          <div className="main">
            <div className="container mt-5 px-5">
              <h3 className="title"></h3>
              <div className="row mtop-minus">
                {/* Left column navigator */}
                <div className="col-sm-3 user_col">
                  <ListGroup className="mt-4">
                    {allCompanies.map((companyItor) => {
                      return (
                        <ListGroup.Item
                          active={company === companyItor ? true : false}
                          action
                          variant="light"
                          className="p-0 mb-2 shadow-none"
                          onClick={() =>
                            this.setState({
                              company: companyItor,
                            })
                          }
                        >
                          <div className="company-item">
                            <img
                              className="company-logo mr-2"
                              src={companyItor.logo}
                              style={{
                                marginRight: 0,
                              }}
                            />
                            <div className="company-name">
                              {companyItor.name}
                            </div>
                          </div>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                  <div
                    onClick={this.newFormToAddNewCompany}
                    className="btn btn-primary btn-new-company mt-3"
                  >
                    + New Project
                  </div>
                </div>
                {/* End left column navigator */}

                <CompanySettings currentUser={currentUser} company={company} />
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}

export default withRouter(Settings);
