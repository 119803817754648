import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import "emoji-mart/css/emoji-mart.css";

class AddCommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment:
        this.props.user.comment !== ""
          ? this.props.user.comment
          : this.props.isEditComment
          ? this.props.comment.comment
          : "",
    };
  }

  handleCommentSubmit = async () => {
    const {
      addPostComment,
      editPostComment,
      postId,
      closeForm,
      parentId,
      isEditComment,
    } = this.props;
    const { comment } = this.state;
    var ret = isEditComment
      ? editPostComment(postId, comment, parentId)
      : await addPostComment(postId, comment, parentId);
    if (parentId !== 0) {
      closeForm();
    }

    ret !== "loginNeeded" && this.setState({ comment: "" });
  };

  render() {
    const { allowCancel, parentComment, isEditComment, comment } = this.props;

    return (
      <Form className={isEditComment ? "mt-0" : "mt-2"}>
        <Form.TextArea
          value={this.state.comment}
          onChange={(e, { value }) => {
            this.setState({ comment: value });
          }}
          className="mb-0"
        />
        <Form.Field className="text-muted" style={{ fontSize: "12px" }}>
          <i class="fab fa-markdown"></i> Markdown is supported. (
          <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank">
            cheatsheet
          </a>
          )
        </Form.Field>
        {parentComment && (
          <Button
            content={isEditComment ? "Save changes" : "Reply"}
            onClick={() => this.handleCommentSubmit()}
            primary
          />
        )}
        {allowCancel && (
          <>
            <Button
              content={isEditComment ? "Save changes" : "Reply"}
              primary
              onClick={() => this.handleCommentSubmit()}
              className="ml-2"
            />
            <Button
              content="Cancel"
              basic
              onClick={this.props.closeForm}
              className="ml-2"
            />
          </>
        )}
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

export default compose(withRouter, connect(mapStateToProps))(AddCommentForm);
