import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import reducer from "./redux/reducer/reducer";

const configureStore = prerloadedState => {
  const middlewares = [thunk.withExtraArgument({})];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const storeEnhancers = [middlewareEnhancer];

  const composedEnhancer = composeWithDevTools(...storeEnhancers);

  const store = createStore(reducer, prerloadedState, composedEnhancer);

  return store;
};

export default configureStore;
