import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { Dropdown, Image } from "semantic-ui-react";
import Cookies from "js-cookie";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  TwitterLoginButton,
  GithubLoginButton,
} from "react-social-login-buttons";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import { connect } from "react-redux";
import { compose } from "redux";
import Badge from "@material-ui/core/Badge";
import "./Topbar.css";
import { setUserValue } from "../../redux/action/userAction";
import default_logo from "../../assets/images/logo.png";

var unsubscribe;
let currentUserListener;

const hostURL = window.location.host.split(".").reverse();
const cookieDomain =
  hostURL.length > 1 ? "." + hostURL[1] + "." + hostURL[0] : null;

const objectToArray = (object) => {
  if (object) {
    return Object.entries(object).map((e) => Object.assign(e[1], { id: e[0] }));
  }
};
class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isLoading: true,
      notifications: [],
    };
    this.toggleLine = this.toggleLine.bind(this);
  }

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  receiveMessage = (event) => {
    // console.log("received event", event);
  };

  componentDidMount = async () => {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      const _fb_token = Cookies.get("_fb_token");
      if (user) {
        // if token is removed, sign out the user
        if (!_fb_token) {
          await firebase
            .auth()
            .signOut()
            .catch((error) => {
              return;
            });
          this.setState({ currentUser: null });
          this.props.setUserValue({ currentUser: null });
        } else {
          currentUserListener = await firebase
            .database()
            .ref("users")
            .child(user.uid)
            .on("value", (snapshot) => {
              this.setState({ currentUser: snapshot.val() });
              this.props.setUserValue({ currentUser: snapshot.val() });
            });

          await firebase
            .database()
            .ref("notifications")
            .child(user.uid)
            .on("value", (snapshot) => {
              this.setState({
                notifications: objectToArray(snapshot.val()) || [],
              });
            });
        }
      } else {
        if (_fb_token && _fb_token !== "init") {
          this.onSignInWithToken(_fb_token);
        } else {
          this.setState({ currentUser: null });
        }
      }
      this.setState({ isLoading: false });
    });
    window.addEventListener("message", this.receiveMessage, false);
  };

  componentWillUnmount() {
    unsubscribe && unsubscribe();
  }

  handleModalClose = () => {
    this.props.setUserValue({
      showSignInModal: false,
      showSignOutModal: false,
    });
  };

  onSignIn = () => {
    this.props.setUserValue({ showSignInModal: true });
  };

  onSignInWithToken = async (token) => {
    try {
      await firebase.auth().signInWithCustomToken(token);
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      await firebase.database().ref("errors").push({
        where: "onSignInWithToken",
        error: error,
        errorCode: errorCode,
        errorMessage: errorMessage,
        createdAt: Date.now(),
      });
    }
  };

  onSocialAuth = async (social) => {
    if (social === "anonymous") {
      try {
        const response = await firebase.auth().signInAnonymously();

        if (response.additionalUserInfo.isNewUser) {
          const user = response.user;

          const userObj = {
            avatar:
              "https://firebasestorage.googleapis.com/v0/b/backlogsco.appspot.com/o/assets%2Fdefault-avatar.jpg?alt=media&token=5995082b-5f5b-4083-a627-64c684a7e17f",
            uid: user.uid,
            displayName: "anonymous",
            signUpVia: "anonymous",
            role: "user",
            notifications: {
              notifyNewComment: true,
              notifyNewFollower: true,
              notifyNewVideo: true,
            },
            createdAt: Date.now(),
          };

          await firebase.database().ref("users").child(user.uid).set(userObj);
        }

        this.handleModalClose();
      } catch (error) {
        // Handle Errors here.
        var errorCode = error.code;
        // The email of the user's account used.
        var email = error.email || "";
        await firebase.database().ref("errors").push({
          where: "onSocialAuth",
          error: error,
          errorCode: errorCode,
          email: email,
        });
        this.handleModalClose();
      }
    } else {
      // normal social login
      var provider;
      if (social === "facebook") {
        provider = new firebase.auth.FacebookAuthProvider();
      }
      if (social === "google") {
        provider = new firebase.auth.GoogleAuthProvider();
      }
      if (social === "twitter") {
        provider = new firebase.auth.TwitterAuthProvider();
      }
      if (social === "github") {
        provider = new firebase.auth.GithubAuthProvider();
      }

      try {
        this.handleModalClose();

        if (cookieDomain) {
          Cookies.set("_fb_uid", "init", {
            path: "",
            domain: cookieDomain,
          });
          Cookies.set("_fb_token", "init", {
            path: "",
            domain: cookieDomain,
          });
        } else {
          Cookies.set("_fb_uid", "init");
          Cookies.set("_fb_token", "init");
        }

        const response = await firebase.auth().signInWithPopup(provider);

        if (response.additionalUserInfo.isNewUser) {
          const user = response.user;
          var userAvatar = user.photoURL;

          if (social === "twitter") {
            userAvatar = user.photoURL.replace("_normal", ""); // get original size twitter avatar
          }

          if (social === "facebook") {
            userAvatar = user.photoURL + "?height=500"; // get larger facebook profile avatar
          }

          const userObj = {
            avatar: userAvatar,
            email: user.email || "",
            emailVerified: false,
            uid: user.uid,
            displayName: user.displayName
              ? user.displayName
              : user.email.split("@")[0],
            signUpVia: social,
            role: "user",
            notifications: {
              notifyNewComment: true,
              notifyNewFollower: true,
              notifyNewVideo: true,
            },
            createdAt: Date.now(),
          };

          await firebase.database().ref("users").child(user.uid).set(userObj);

          // send email verification email
          // await response.user.sendEmailVerification();
        }

        var userSignIn = await firebase.functions().httpsCallable("userSignIn");
        const token = await userSignIn({
          userUid: response.user.uid,
        });

        // update cookies
        if (cookieDomain) {
          Cookies.set("_fb_uid", response.user.uid, {
            path: "",
            domain: cookieDomain,
          });
          Cookies.set("_fb_token", token.data, {
            path: "",
            domain: cookieDomain,
          });
        } else {
          Cookies.set("_fb_uid", response.user.uid);
          Cookies.set("_fb_token", token.data);
        }

        window.location.reload();
      } catch (error) {
        // Handle Errors here.
        var errorCode = error.code;
        // The email of the user's account used.
        var email = error.email || "";
        await firebase.database().ref("errors").push({
          where: "onSocialAuth",
          error: error,
          errorCode: errorCode,
          email: email,
        });
        this.handleModalClose();
      }
    }
  };

  showSignInForm = () => {
    return (
      <div>
        <button
          className="mb-2 anonymous-login-btn"
          onClick={() => this.onSocialAuth("anonymous")}
        >
          <div
            style={{ alignItems: "center", display: "flex", height: "100%" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                minWidth: "22px",
              }}
            >
              👻
            </div>
            <div style={{ width: "10px" }}></div>
            <div style={{ textAlign: "center", width: "100%" }}>
              Keep Anonymous
            </div>
          </div>
        </button>
        <TwitterLoginButton
          className="mb-2"
          onClick={() => this.onSocialAuth("twitter")}
          align="center"
          iconSize="22px"
          style={{ fontSize: 17 }}
        />
        <GithubLoginButton
          className="mb-2"
          onClick={() => this.onSocialAuth("github")}
          align="center"
          iconSize="22px"
          style={{ fontSize: 17 }}
        />
        <GoogleLoginButton
          className="mb-2"
          onClick={() => this.onSocialAuth("google")}
          align="center"
          iconSize="22px"
          style={{ fontSize: 17 }}
        />
        <FacebookLoginButton
          className="mb-2"
          onClick={() => this.onSocialAuth("facebook")}
          align="center"
          iconSize="22px"
          style={{ fontSize: 17 }}
        />
        <button className="mb-2 login-btn" onClick={() => this.gotoLogin()}>
          <div
            style={{ alignItems: "center", display: "flex", height: "100%" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                minWidth: "22px",
              }}
            >
              <i className="far fa-envelope"></i>
            </div>
            <div style={{ width: "10px" }}></div>
            <div style={{ textAlign: "center", width: "100%" }}>
              Login with Email
            </div>
          </div>
        </button>
      </div>
    );
  };

  onOpenSignOutModal = () => {
    this.props.setUserValue({ showSignOutModal: true });
  };

  onSignOut = async () => {
    const userUid = this.state.currentUser.uid;
    try {
      // remove cookies first
      if (cookieDomain) {
        Cookies.remove("_fb_uid", { path: "", domain: cookieDomain });
        Cookies.remove("_fb_token", { path: "", domain: cookieDomain });
      } else {
        Cookies.remove("_fb_uid");
        Cookies.remove("_fb_token");
      }

      await firebase
        .database()
        .ref("users")
        .child(this.state.currentUser.uid)
        .off("value", currentUserListener);
      await firebase.auth().signOut();

      this.props.setUserValue({ currentUser: null });
      this.handleModalClose();
    } catch (error) {
      await firebase.database().ref("errors").push({
        where: "onSignOut",
        error: error,
        userUid: userUid,
      });
    }
    this.props.history.push("/");
  };

  showSignOutForm = () => {
    return (
      <div className="row">
        <div className="col-6 text-center">
          <button
            className=" btn btn-primary px-5 my-1"
            onClick={() => {
              this.onSignOut();
            }}
          >
            Sign out
          </button>
        </div>
        <div className="col-6 text-center">
          <button
            className="btn btn-secondary px-5"
            onClick={() => this.handleModalClose()}
          >
            Go back
          </button>
        </div>
      </div>
    );
  };

  gotoProfile = () => {
    this.props.history.push("/profile");
  };

  gotoDashboard = () => {
    window.open("https://backlogs.co/dashboard", "_blank");
  };

  showNotifications = () => {
    this.props.history.push("/notifications");
  };

  gotoLogin = () => {
    this.handleModalClose();
    this.props.history.push("/login");
  };

  render() {
    const { currentUser, isLoading, notifications } = this.state;
    const { company, user } = this.props;
    const { showSignInModal, showSignOutModal } = user;

    const userMenuTrigger = (
      <span>
        <Image avatar src={currentUser && currentUser.avatar} />
      </span>
    );

    const userMenuOptions = [
      {
        key: "profile",
        text: "Profile",
        icon: "user outline",
        onClick: this.gotoProfile,
      },
      {
        key: "sign-out",
        text: "Sign Out",
        icon: "arrow alternate circle right outline",
        onClick: this.onOpenSignOutModal,
      },
    ];

    const adminMenuOptions = [
      {
        key: "profile",
        text: "Profile",
        icon: "user outline",
        onClick: this.gotoProfile,
      },
      {
        key: "dashboard",
        text: "Dashboard",
        icon: "computer",
        onClick: this.gotoDashboard,
      },
      {
        key: "sign-out",
        text: "Sign Out",
        icon: "arrow alternate circle right outline",
        onClick: this.onOpenSignOutModal,
      },
    ];

    return (
      <React.Fragment>
        <Modal
          show={showSignInModal}
          onHide={this.handleModalClose}
          centered
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>Log in</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.showSignInForm()}</Modal.Body>
        </Modal>
        <Modal show={showSignOutModal} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Do you want to sign out?</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.showSignOutForm()}</Modal.Body>
        </Modal>
        <header id="topnav" className="defaultscroll nav-sticky">
          <Container>
            <div>
              <a className="logo" href={company ? "/" : "https://backlogs.co"}>
                <img
                  src={(company && company.logo) || default_logo}
                  alt=""
                  style={{ width: "35px", marginRight: "10px" }}
                  className="logo-img"
                />
                {company ? company.name : "Backlogs"}
              </a>
            </div>
            <div className="login-button">
              {currentUser ? (
                <div
                  className="row"
                  style={{
                    marginTop: "2px",
                    marginRight: "5px",
                    paddingLeft: "5px",
                  }}
                >
                  {/* <div
                    onClick={() => this.showNotifications()}
                    style={{
                      lineHeight: "68px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                  >
                    {notifications.length > 0 ? (
                      <Badge color="secondary" badgeContent=" " variant="dot">
                        <i className="far fa-bell fa-lg" />
                      </Badge>
                    ) : (
                      <Badge>
                        <i className="far fa-bell fa-lg" />
                      </Badge>
                    )}
                  </div> */}
                  <Dropdown
                    trigger={userMenuTrigger}
                    options={
                      currentUser.role === "user"
                        ? userMenuOptions
                        : adminMenuOptions
                    }
                    pointing="top right"
                    icon={null}
                  />
                </div>
              ) : (
                <div
                  onClick={this.onSignIn}
                  className="btn btn-outline-primary"
                >
                  Log in
                </div>
              )}
            </div>
            <div className="menu-extras">
              <div className="menu-item">
                <Link
                  to="#"
                  onClick={this.toggleLine}
                  className={
                    this.state.isOpen ? "navbar-toggle open" : "navbar-toggle"
                  }
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>
            <div
              id="navigation"
              style={{
                display: this.state.isOpen ? "block" : "none",
                float: "right",
              }}
            >
              <ul
                className="navigation-menu"
                id="top-menu"
                style={{
                  float: "left",
                  paddingTop: "3px",
                  marginLeft: "10px",
                }}
              >
                <li>
                  <a href="/" className="nav-btn">
                    Roadmap
                  </a>
                </li>
                <li>
                  <a href="/post" className="nav-btn">
                    Feedback
                  </a>
                </li>
                <li>
                  <a href="/changelog" className="nav-btn">
                    Changelog
                  </a>
                </li>
              </ul>
            </div>{" "}
          </Container>
        </header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Topbar);
