import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import firebase from "firebase/app";
import "firebase/database";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { setUserValue } from "../../redux/action/userAction";
import { Row, Col } from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";
import { DragDropContext } from "react-beautiful-dnd";
import AddToRoadmap from "../../components/modals/AddToRoadmap";
import no_post_img from "../../assets/images/no-posts.png";
import Board from "./Board";
import "./Roadmap.scss";

var allPostsListener;

class Roadmap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        posts: null,
        boards: {
          Pending: {
            id: "Pending",
            title: "Pending",
            postIds: [],
          },
          Planned: {
            id: "Planned",
            title: "Planned",
            postIds: [],
          },
          Ongoing: {
            id: "Ongoing",
            title: "Ongoing",
            postIds: [],
          },
        },
        boardOrder: ["Pending", "Planned", "Ongoing"],
      },
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    const { subdomain, companyExists } = this.props;

    if (!companyExists) {
      this.props.history.push("/error");
      return;
    }

    allPostsListener = await firebase
      .database()
      .ref("company-posts")
      .child(subdomain)
      .child("active")
      .orderByChild("priority")
      .on("value", (snapshot) => {
        if (snapshot.val() === null || snapshot.val() === undefined) {
          this.setState({ isLoading: false });
          return;
        }

        var pendingArray = [];
        var plannedArray = [];
        var ongoingArray = [];
        var completedArray = [];

        snapshot.forEach((post) => {
          if (post.val().status === "PENDING") {
            pendingArray.push(post.val().id);
          } else if (post.val().status === "PLANNED") {
            plannedArray.push(post.val().id);
          } else if (post.val().status === "ONGOING") {
            ongoingArray.push(post.val().id);
          } else {
            completedArray.push(post.val().id);
          }
        });

        const newData = {
          posts: snapshot.val(),
          boards: {
            Pending: {
              id: "Pending",
              title: "Pending",
              postIds: pendingArray.reverse(),
            },
            Planned: {
              id: "Planned",
              title: "Planned",
              postIds: plannedArray.reverse(),
            },
            Ongoing: {
              id: "Ongoing",
              title: "Ongoing",
              postIds: ongoingArray.reverse(),
            },
          },
          boardOrder: ["Pending", "Planned", "Ongoing"],
        };

        this.setState({
          data: newData,
          isLoading: false,
        });
      });
  };

  componentWillUnmount = async () => {
    this.props.subdomain &&
      (await firebase
        .database()
        .ref("company-posts")
        .child(this.props.subdomain)
        .child("active")
        .off("value", allPostsListener));
  };

  onDragEnd = async (result) => {
    const { subdomain } = this.props;
    const { destination, source, draggableId } = result;
    const { data } = this.state;
    if (!destination) return;

    const start = data.boards[source.droppableId];
    const finish = data.boards[destination.droppableId];

    // do nothing
    if (start === finish && source.index === destination.index) {
      return;
    }

    var destPriority = 0;
    var preDestPriority = Date.now();
    var postDestPriority = 0;
    var targetPriority = 0;

    // move card in the same board
    if (start === finish) {
      const newpostIds = Array.from(start.postIds);
      const destPost = newpostIds[destination.index];

      const destRef = await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(destPost)
        .once("value");

      destPriority = destRef.val().priority;

      if (destination.index > source.index) {
        if (destination.index === newpostIds.length - 1) {
          postDestPriority = destPriority - 1000;
        } else {
          const postDestRef = await firebase
            .database()
            .ref("company-posts")
            .child(subdomain)
            .child("active")
            .child(newpostIds[destination.index + 1])
            .once("value");
          postDestPriority = postDestRef.val().priority;
        }

        targetPriority =
          (destPriority - postDestPriority) / 2 + postDestPriority;
      } else {
        if (destination.index > 0) {
          const preDestRef = await firebase
            .database()
            .ref("company-posts")
            .child(subdomain)
            .child("active")
            .child(newpostIds[destination.index - 1])
            .once("value");
          preDestPriority = preDestRef.val().priority;
        }
        targetPriority = (preDestPriority - destPriority) / 2 + destPriority;
      }

      await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(draggableId)
        .update({ priority: targetPriority });

      return;
    }

    // move card across different boards
    const finishpostIds = Array.from(finish.postIds);

    if (finishpostIds.length === 0) {
      // empty board
      targetPriority = Date.now();
    } else {
      if (destination.index === finishpostIds.length) {
        destPriority = 0;
      } else {
        const destRef = await firebase
          .database()
          .ref("company-posts")
          .child(subdomain)
          .child("active")
          .child(finishpostIds[destination.index])
          .once("value");
        destPriority = destRef.val().priority;
      }

      if (destination.index === 0) {
        preDestPriority = Date.now();
      } else {
        const preDestRef = await firebase
          .database()
          .ref("company-posts")
          .child(subdomain)
          .child("active")
          .child(finishpostIds[destination.index - 1])
          .once("value");
        preDestPriority = preDestRef.val().priority;
      }

      targetPriority = (preDestPriority - destPriority) / 2 + destPriority;
    }

    await firebase
      .database()
      .ref("company-posts")
      .child(subdomain)
      .child("active")
      .child(draggableId)
      .update({ priority: targetPriority, status: finish.id.toUpperCase() });
  };

  render() {
    const { data, isLoading } = this.state;
    const { subdomain, user, company } = this.props;
    const { currentUser } = user;
    const subdomainCap = subdomain.charAt(0).toUpperCase() + subdomain.slice(1);

    return (
      <main className="bg-light board-container">
        <Helmet>
          <title>{"Roadmap | " + subdomainCap}</title>
          <meta
            name="description"
            content={
              "Report bugs or request features for " +
              subdomainCap +
              " on backlogs.co"
            }
          />
        </Helmet>
        <AddToRoadmap subdomain={subdomain} />
        <nav className="nav"></nav>
        <div className="content board">
          <div className="container top-wrap">
            {isLoading ? (
              <div className="text-center" style={{ marginTop: 50 }}>
                <PulseLoader sizeUnit={"px"} size={8} color={"#66b0ff"} />
              </div>
            ) : !(
                currentUser &&
                currentUser.companies &&
                currentUser.companies[subdomain]
              ) &&
              (!data.posts ||
                (data.posts && Object.keys(data.posts).length === 0)) ? (
              <div className="container">
                <Row className="justify-content-center">
                  <Col lg={8} md={12} className="text-center">
                    <img
                      src={no_post_img}
                      className="img-fluid"
                      alt=""
                      style={{ width: "420px" }}
                    />
                    <div className="text-capitalize text-dark mb-4 error-page">
                      No items in roadmap.
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="row">
                <DragDropContext onDragEnd={this.onDragEnd}>
                  {data.boardOrder.map((boardId) => {
                    const board = data.boards[boardId];
                    const posts = board.postIds.map(
                      (postId) => data.posts[postId]
                    );

                    return (
                      <Board
                        key={board.id}
                        title={board.id}
                        board={board}
                        cards={posts}
                        subdomain={subdomain}
                      />
                    );
                  })}
                </DragDropContext>
              </div>
            )}
          </div>
          {company && company.whiteLabel ? null : (
            <footer className="bottom-wrap d-flex align-items-center justify-content-center">
              <p className="mb-0" style={{ fontSize: 13 }}>
                <a
                  href="https://backlogs.co"
                  target="_blank"
                  className="footer-attribution"
                  style={{ color: "inherit", textDecoration: "none" }}
                  rel="noopener noreferrer"
                >
                  Powered with <i className="fas fa-heart text-danger"></i> by
                  Backlogs
                </a>
              </p>
            </footer>
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Roadmap);
