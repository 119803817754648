import React from "react";
import { format } from "date-fns";
import { Feed } from "semantic-ui-react";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

class PostChangeLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isImageOpen: false,
    };
  }

  render() {
    const { post } = this.props;
    const { isImageOpen } = this.state;

    var logsArray = Object.values(post.changeLogs);
    return (
      <Feed>
        {logsArray.length > 0 && (
          <div className="post-sub-title">Changelog</div>
        )}
        {logsArray &&
          logsArray.map((log) => {
            return (
              <>
                <Feed.Event
                  icon="check circle"
                  date={format(log.createdAt, "PP")}
                  summary={
                    "This " +
                    (post.type === "BUG" ? "bug" : "feature") +
                    " is marked as " +
                    log.status.toUpperCase()
                  }
                  extraText={log.comment}
                  key={log.createdAt}
                />
                {log.photo && (
                  <img
                    src={log.photo}
                    width="300px"
                    alt=""
                    style={{ marginLeft: "50px", cursor: "pointer" }}
                    onClick={() => this.setState({ isImageOpen: true })}
                  />
                )}
                {isImageOpen && (
                  <Lightbox
                    mainSrc={log.photo}
                    onCloseRequest={() => this.setState({ isImageOpen: false })}
                  />
                )}
              </>
            );
          })}
      </Feed>
    );
  }
}

export default PostChangeLogs;
