import React from "react";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import firebase from "firebase/app";
import "firebase/database";

class ProfileSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: this.props.currentUser.displayName
        ? this.props.currentUser.displayName
        : "",
      email: this.props.currentUser.email ? this.props.currentUser.email : "",
      productName: this.props.currentUser.productName
        ? this.props.currentUser.productName
        : "",
    };

    this.onChangeText = this.onChangeText.bind(this);
  }

  onChangeText = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  onUpdateAccount = async () => {
    const { displayName, email, productName } = this.state;

    if (displayName === "" || email === "") {
      toast.error("Sorry, name and email cannot be empty.", {
        autoClose: 4000,
      });
      return;
    }

    await firebase
      .database()
      .ref("users")
      .child(this.props.currentUser.uid)
      .update({
        displayName: displayName,
        email: email,
        productName: productName,
      });

    toast.success("Your info is saved.", {
      autoClose: 3000,
    });
  };

  render() {
    const { displayName, email, productName } = this.state;

    return (
      <div className="col-sm-9">
        <div className="border">
          <h3 className="mt-2" style={{ fontWeight: 600 }}>
            Edit Profile
          </h3>
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <a
                className="nav-item nav-link active pl-0"
                id="nav-home-tab"
                data-toggle="tab"
                href="#nav-home"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                Account
              </a>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <Form>
                <Form.Group controlId="displayName">
                  <Form.Label>Display Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="John Doe"
                    value={displayName}
                    onChange={this.onChangeText}
                  />
                </Form.Group>

                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="john@doe.com"
                    value={email}
                    onChange={this.onChangeText}
                  />
                </Form.Group>

                <Form.Group controlId="productName">
                  <Form.Label>
                    Your product name{" "}
                    <span
                      style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.4)" }}
                    >
                      (optional)
                    </span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Backlogs"
                    value={productName}
                    onChange={this.onChangeText}
                  />
                </Form.Group>
              </Form>
              <div className="text-right border-less">
                <div className="col-12">
                  <button
                    className="btn btn-lg btn-primary"
                    onClick={this.onUpdateAccount}
                  >
                    <i className="fas fa-save "></i> Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileSettings;
