import React from "react";
import { format } from "date-fns";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import Linkify from "linkifyjs/react";
import { formatDistance } from "date-fns";
import firebase from "firebase/app";
import "firebase/database";
import ReactMarkdown from "react-markdown";
import UpdatePostStatus from "../../components/modals/UpdatePostStatus";
import { setUserValue } from "../../redux/action/userAction";
import CodeBlock from "../../components/utils/CodeBlock";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

class PostContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isImageOpen: false,
    };
  }
  componentDidMount = async () => {
    var status = "active";
    if (this.props.post.status === "COMPLETED") {
      status = "completed";
    }
    if (this.props.post.status === "NOT AN ISSUE") {
      status = "closed";
    }

    const postRef = await firebase
      .database()
      .ref("company-posts")
      .child(this.props.subdomain)
      .child(status)
      .child(this.props.post.id)
      .child("stats")
      .child("viewCount");
    postRef.transaction(function (currentViewCount) {
      return (currentViewCount || 0) + 1;
    });
  };
  onUpvote = async (post) => {
    const { currentUser } = this.props.user;
    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }

    var status = "active";
    if (this.props.post.status === "COMPLETED") {
      status = "completed";
    }
    if (this.props.post.status === "NOT AN ISSUE") {
      status = "closed";
    }

    const upvoteRef = await firebase
      .database()
      .ref("company-posts")
      .child(post.product)
      .child(status)
      .child(post.id)
      .child("voteCount");
    upvoteRef.transaction(function (currentVoteCount) {
      return (currentVoteCount || 0) + 1;
    });

    await firebase
      .database()
      .ref("company-posts")
      .child(post.product)
      .child(status)
      .child(post.id)
      .child("votedBy")
      .child(currentUser.uid)
      .set({
        avatar: currentUser.avatar,
        name: currentUser.displayName,
        email: currentUser.email || "",
      });
  };

  onUnupvote = async (post) => {
    const { currentUser } = this.props.user;

    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }

    var status = "active";
    if (this.props.post.status === "COMPLETED") {
      status = "completed";
    }
    if (this.props.post.status === "NOT AN ISSUE") {
      status = "closed";
    }

    const upvoteRef = await firebase
      .database()
      .ref("company-posts")
      .child(post.product)
      .child(status)
      .child(post.id)
      .child("voteCount");
    upvoteRef.transaction(function (currentVoteCount) {
      return currentVoteCount - 1;
    });

    await firebase
      .database()
      .ref("company-posts")
      .child(post.product)
      .child(status)
      .child(post.id)
      .child("votedBy")
      .child(currentUser.uid)
      .remove();
  };

  render() {
    const { post, user, subdomain } = this.props;
    const { currentUser } = user;
    const { isImageOpen } = this.state;

    var tmpDate = formatDistance(post.createdAt, Date.now());
    const newDateFormat =
      tmpDate.split(" ")[0] === "about"
        ? tmpDate.replace("about", "")
        : tmpDate;

    return (
      <div className="postdetails-item">
        <UpdatePostStatus post={post} subdomain={subdomain} />
        <div className="postdetails-details">
          <div className="postdetails-header">
            <div className="left">
              <div className="postdetails-upvote">
                {post.votedBy &&
                currentUser &&
                post.votedBy[currentUser.uid] ? (
                  <span
                    className="upvote-count"
                    style={{ backgroundColor: "#4e9aeb", color: "#f6f6f6" }}
                    onClick={() => this.onUnupvote(post)}
                  >
                    <i className="fas fa-arrow-up"></i>
                    {post.voteCount}
                  </span>
                ) : (
                  <span
                    className="upvote-count"
                    onClick={() => this.onUpvote(post)}
                  >
                    <i className="fas fa-arrow-up"></i>
                    {post.voteCount}
                  </span>
                )}
              </div>
              <div>
                <p className="postdetails-title">{post.name}</p>
                <div className="statusStale">
                  {post.status === "PENDING" ? (
                    <div className="uppercaseHeader postStatus pending">
                      PENDING
                    </div>
                  ) : post.status === "PLANNED" ? (
                    <div className="uppercaseHeader postStatus planned">
                      PLANNED
                    </div>
                  ) : post.status === "ONGOING" ? (
                    <div className="uppercaseHeader postStatus ongoing">
                      ONGOING
                    </div>
                  ) : post.status === "COMPLETED" ? (
                    <div className="uppercaseHeader postStatus completed">
                      COMPLETED
                    </div>
                  ) : (
                    <div className="uppercaseHeader postStatus not-an-issue">
                      NOT AN ISSUE
                    </div>
                  )}
                  {currentUser &&
                    currentUser.companies &&
                    currentUser.companies[subdomain] && (
                      <span
                        className="update-status"
                        onClick={() =>
                          this.props.setUserValue({
                            showUpdatePostStatusModal: true,
                          })
                        }
                      >
                        <i className="far fa-edit"></i> Update
                      </span>
                    )}
                </div>
              </div>
            </div>
            <div className="right">
              <time className="feedback-date" dateTime={post.createdAt}>
                <span className="date-published">{newDateFormat} ago</span>
              </time>
            </div>
          </div>
          <div className="user">
            <img
              className="profile-pic mr-2"
              src={post.submitter.avatar}
              style={{
                marginRight: 0,
              }}
            />
            <div className="col m-auto pl-0">
              <div className="user-name">{post.submitter.name}</div>
              {post.submitter.productName &&
                post.submitter.productName !== "" && (
                  <div className="user-product">
                    {post.submitter.productName}
                  </div>
                )}
            </div>
          </div>

          <p className="postdetails-description">
            <Linkify style={{ whiteSpace: "pre-line" }}>
              <ReactMarkdown
                source={post.details}
                renderers={{ code: CodeBlock }}
              />
            </Linkify>
          </p>
          {post.photo && (
            <img
              src={post.photo}
              width="50%"
              alt=""
              className="my-3"
              style={{ cursor: "pointer" }}
              onClick={() => this.setState({ isImageOpen: true })}
            />
          )}
          {isImageOpen && (
            <Lightbox
              mainSrc={post.photo}
              onCloseRequest={() => this.setState({ isImageOpen: false })}
            />
          )}
          {currentUser && currentUser.uid === post.submitter.uid && (
            <div className="modify-post">
              <span
                className="edit-post"
                onClick={() =>
                  this.props.setUserValue({
                    showUpdateFeedbackModal: true,
                  })
                }
              >
                <i className="far fa-edit"></i> Edit
              </span>
              <span
                className="delete-post"
                onClick={() =>
                  this.props.setUserValue({
                    showDeleteFeedbackModal: true,
                  })
                }
              >
                <i className="fas fa-trash-alt"></i> Delete
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PostContent);
