import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { formatDistance } from "date-fns";
import { withRouter } from "react-router";
import ReactMarkdown from "react-markdown";
import Linkify from "linkifyjs/react";
import {
  FacebookIcon,
  TwitterIcon,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import firebase from "firebase/app";
import "firebase/database";
import PulseLoader from "react-spinners/PulseLoader";
import CodeBlock from "../../components/utils/CodeBlock";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";
import UpdateChangelogModal from "../../components/modals/UpdateChangelogModal";
import DeleteChangelogModal from "../../components/modals/DeleteChangelogModal";

var changelogListener;
class ChangelogDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageOpen: false,
      changelog: null,
      isLoading: true,
      currentURL: window.location.href,
    };
  }

  componentDidMount = async () => {
    var changelogId = this.props.match.params.changelogId;

    const { subdomain, companyExists } = this.props;
    if (!companyExists) {
      this.props.history.push("/error");
      return;
    }

    changelogListener = await firebase
      .database()
      .ref("company-changelogs")
      .child(subdomain)
      .child(changelogId)
      .on("value", (snapshot) => {
        if (snapshot.val() === null || snapshot.val() === undefined) {
          this.setState({ isLoading: false });
        } else {
          this.setState({ changelog: snapshot.val(), isLoading: false });
        }
      });
  };

  componentWillUnmount = async () => {
    var changelogId = this.props.match.params.changelogId;

    const { subdomain } = this.props;

    await firebase
      .database()
      .ref("company-changelogs")
      .child(subdomain)
      .child(changelogId)
      .off("value", changelogListener);
  };

  goToChangelogDetails = (changelog) => {
    this.props.history.push("/changelog/" + changelog.postId);
  };

  goBack = () => {
    this.props.history.push("/changelog");
  };

  render() {
    const { imageOpen, changelog, currentURL } = this.state;

    const { subdomain, user } = this.props;
    const { currentUser } = user;
    const subdomainCap = subdomain.charAt(0).toUpperCase() + subdomain.slice(1);

    if (changelog === null) {
      return (
        <div className="text-center" style={{ marginTop: 150 }}>
          <PulseLoader sizeUnit={"px"} size={8} color={"#66b0ff"} />
        </div>
      );
    }

    const {
      title,
      description,
      type,
      photo,
      postId,
      createdAt,
      admin,
    } = changelog;
    var tmpDate = formatDistance(createdAt, Date.now());
    const newDateFormat =
      tmpDate.split(" ")[0] === "about"
        ? tmpDate.replace("about", "")
        : tmpDate;
    const typeColor = type === "BUG" ? "danger" : "success";

    var newType = "";
    if (type === "BUG") {
      newType = "Bug";
    } else if (type === "FEATURE") {
      newType = "Feature";
    } else {
      newType = "Other";
    }

    return (
      <Fragment>
        <Helmet>
          <title>{title + " | " + subdomainCap}</title>
          <meta
            name="description"
            content={
              "Report bugs or request features for " +
              subdomainCap +
              " on backlogs.co"
            }
          />
        </Helmet>
        <UpdateChangelogModal subdomain={subdomain} changelog={changelog} />
        <DeleteChangelogModal subdomain={subdomain} changelog={changelog} />
        <section className="changelog-container">
          <div className="container">
            <div className="row justify-content-md-center pt-3 pt-lg-4 changelogItem">
              <div className="col-12">
                <button className="back" onClick={() => this.goBack()}>
                  👈 Go back
                </button>

                <article className="article" id={changelog.createdAt}>
                  <div className="titleDiv">
                    <div className="left">
                      <h2
                        className="log-title"
                        onClick={() => this.goToChangelogDetails(changelog)}
                      >
                        {title}
                      </h2>
                    </div>
                    <div className="right">
                      <time className="article-date" dateTime={createdAt}>
                        <span className="date-published">
                          {newDateFormat} ago
                        </span>
                      </time>
                    </div>
                  </div>

                  <div className="article-meta">
                    <div key={type} className={`tag mr-2 bg-${typeColor}`}>
                      {newType}
                    </div>
                  </div>
                  <div className="article-content">
                    <Linkify style={{ whiteSpace: "pre-line" }}>
                      <ReactMarkdown
                        source={description}
                        renderers={{ code: CodeBlock }}
                      />
                    </Linkify>
                    {photo && (
                      <figure className="article-figure mt-2">
                        <img
                          src={photo}
                          className="figure-img img-fluid"
                          alt={title}
                          style={{
                            cursor: "pointer",
                            maxHeight: "400px",
                            width: "auto",
                          }}
                          onClick={() => this.setState({ imageOpen: true })}
                        />
                      </figure>
                    )}
                    {imageOpen && (
                      <Lightbox
                        mainSrc={photo}
                        onCloseRequest={() =>
                          this.setState({ imageOpen: false })
                        }
                      />
                    )}
                  </div>
                  {currentUser && admin && currentUser.uid === admin.uid && (
                    <div className="modify-changelog">
                      <span
                        className="edit-changelog"
                        onClick={() =>
                          this.props.setUserValue({
                            showUpdateChangelogModal: true,
                          })
                        }
                      >
                        <i className="far fa-edit"></i> Edit
                      </span>
                      <span
                        className="delete-changelog"
                        onClick={() =>
                          this.props.setUserValue({
                            showDeleteChangelogModal: true,
                          })
                        }
                      >
                        <i className="fas fa-trash-alt"></i> Delete
                      </span>
                    </div>
                  )}
                  <div className="social-share-btn">
                    <FacebookShareButton
                      url={currentURL}
                      quote={"[New Changelog] " + title}
                    >
                      <FacebookIcon size={25} round={true} className="mr-2" />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={currentURL}
                      via={"backlogsco"}
                      related={["backlogsco"]}
                      title={title}
                    >
                      <TwitterIcon size={25} round={true} className="mr-2" />
                    </TwitterShareButton>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChangelogDetails);
