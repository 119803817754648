import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import firebase from "firebase/app";
import "firebase/database";
import { setUserValue } from "../../redux/action/userAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import "./PostItem.css";

class PostItem extends React.Component {
  // onUpvote = async (post) => {
  //   const { currentUser } = this.props.user;
  //   if (!currentUser) {
  //     return this.props.setUserValue({ showSignInModal: true });
  //   }
  //   const upvoteRef = await firebase
  //     .database()
  //     .ref("company-posts")
  //     .child(post.product)
  //     .child(post.id)
  //     .child("voteCount");
  //   upvoteRef.transaction(function (currentVoteCount) {
  //     return (currentVoteCount || 0) + 1;
  //   });

  //   await firebase
  //     .database()
  //     .ref("company-posts")
  //     .child(post.product)
  //     .child(post.id)
  //     .child("votedBy")
  //     .child(currentUser.uid)
  //     .set({
  //       avatar: currentUser.avatar,
  //       name: currentUser.displayName,
  //       email: currentUser.email,
  //     });
  // };

  // onUnupvote = async (post) => {
  //   const { currentUser } = this.props.user;

  //   if (!currentUser) {
  //     return this.props.setUserValue({ showSignInModal: true });
  //   }

  //   const upvoteRef = await firebase
  //     .database()
  //     .ref("company-posts")
  //     .child(post.product)
  //     .child(post.id)
  //     .child("voteCount");
  //   upvoteRef.transaction(function (currentVoteCount) {
  //     return currentVoteCount - 1;
  //   });

  //   await firebase
  //     .database()
  //     .ref("company-posts")
  //     .child(post.product)
  //     .child(post.id)
  //     .child("votedBy")
  //     .child(currentUser.uid)
  //     .remove();
  // };

  goToPost = (post) => {
    this.props.history.push("/post/" + post.id);
  };

  render() {
    const { post, user } = this.props;
    const { currentUser } = user;

    return (
      <div className="post-item">
        <div className="post-upvote">
          {post.votedBy && currentUser && post.votedBy[currentUser.uid] ? (
            <span
              className="upvote-count"
              style={{ backgroundColor: "#4e9aeb", color: "#f6f6f6" }}
              onClick={() => this.goToPost(post)}
            >
              <i className="fas fa-arrow-up"></i>
              {post.voteCount}
            </span>
          ) : (
            <span className="upvote-count" onClick={() => this.goToPost(post)}>
              <i className="fas fa-arrow-up"></i>
              {post.voteCount}
            </span>
          )}
        </div>
        <div
          className="post-details"
          onClick={() => this.props.history.push("/post/" + post.id)}
        >
          <p className="post-title">{post.name}</p>
          <div className="statusStale">
            {post.status === "PENDING" ? (
              <div className="uppercaseHeader postStatus pending">PENDING</div>
            ) : post.status === "PLANNED" ? (
              <div className="uppercaseHeader postStatus planned">PLANNED</div>
            ) : post.status === "ONGOING" ? (
              <div className="uppercaseHeader postStatus ongoing">ONGOING</div>
            ) : (
              <div className="uppercaseHeader postStatus completed">
                COMPLETED
              </div>
            )}
          </div>
          <p className="post-description">{post.details}</p>
          <div>
            <span className="text-muted mr-3" style={{ fontSize: "14px" }}>
              <FontAwesomeIcon icon={faCommentAlt} className="fa-xs" />{" "}
              {post.stats.replyCount} Replies
            </span>
            <span className="text-muted mr-3" style={{ fontSize: "14px" }}>
              <FontAwesomeIcon icon={faEye} className="fa-xs" />{" "}
              {post.stats.viewCount} Views
            </span>
          </div>
        </div>
        <div className="user mr-2">
          <img className="profile-pic mr-2" src={post.submitter.avatar} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PostItem);
