import React, { Component } from "react";
import "./Footer.css";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <footer className="bottom-wrap d-flex align-items-center justify-content-center">
        <p className="mb-0" style={{ fontSize: 13 }}>
          <a
            href="https://backlogs.co"
            target="_blank"
            className="footer-attribution"
            style={{ color: "inherit", textDecoration: "none" }}
            rel="noopener noreferrer"
          >
            Powered with <i className="fas fa-heart text-danger"></i> by
            Backlogs
          </a>
        </p>
      </footer>
    );
  }
}

export default Footer;
