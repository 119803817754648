import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import PostList from "../../components/PostList/PostList";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import "./Posts.css";
import { setUserValue } from "../../redux/action/userAction";
import AddNewFeatureModal from "../../components/modals/AddNewFeature";
import AddNewBugModal from "../../components/modals/AddNewBug";
import AddNewFeedbackModal from "../../components/modals/AddNewFeedback";

class Post extends React.Component {
  onAddNewFeature = () => {
    const { currentUser } = this.props.user;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
    } else {
      this.props.setUserValue({ showAddNewFeatureModal: true });
    }
  };

  onAddNewBug = () => {
    const { currentUser } = this.props.user;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
    } else {
      this.props.setUserValue({ showAddNewBugModal: true });
    }
  };

  onAddOtherFeedback = () => {
    const { currentUser } = this.props.user;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
    } else {
      this.props.setUserValue({ showAddNewFeedbackModal: true });
    }
  };

  render() {
    const { subdomain, companyExists } = this.props;
    const subdomainCap = subdomain.charAt(0).toUpperCase() + subdomain.slice(1);

    return (
      <Fragment>
        <Helmet>
          <title>{"Feedback | " + subdomainCap}</title>
          <meta
            name="description"
            content={
              "Report bugs or request features for " +
              subdomainCap +
              " on backlogs.co"
            }
          />
        </Helmet>
        <AddNewFeatureModal subdomain={subdomain} />
        <AddNewBugModal subdomain={subdomain} />
        <AddNewFeedbackModal subdomain={subdomain} />
        <section className="bg-post">
          <div className="container">
            <div className="post-action-list mt-4">
              <Button
                variant="outline-dark"
                className={"my-1 mx-1 rounded btn-action"}
                onClick={() => this.onAddNewBug()}
              >
                Report Bug
              </Button>

              <Button
                variant="outline-dark"
                className={"my-1 mx-1 rounded btn-action"}
                onClick={() => this.onAddNewFeature()}
              >
                Request Feature
              </Button>

              <Button
                variant="outline-dark"
                className={" my-1 mx-1 rounded btn-action"}
                onClick={() => this.onAddOtherFeedback()}
              >
                Other Feedback
              </Button>
            </div>
            <PostList subdomain={subdomain} companyExists={companyExists} />
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Post);
