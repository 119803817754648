import React from "react";
import { Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { uuid } from "uuidv4";
import { toast } from "react-toastify";
import ImageUploader from "react-images-upload";
import PulseLoader from "react-spinners/PulseLoader";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { setUserValue } from "../../redux/action/userAction";
import "./NewFormModal.css";

class AddToRoadmap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      photo: "",
      pictures: [],
      name: "",
      status: "",
      type: "",
      details: "",
      isSubmitting: false,
    };

    this.onDrop = this.onDrop.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.user.addToRoadmapStatus !== prevProps.user.addToRoadmapStatus
    ) {
      this.setState({ status: this.props.user.addToRoadmapStatus });
    }
  };

  handleModalClose = () => {
    this.setState({
      loading: false,
      photo: "",
      name: "",
      details: "",
      status: "",
      type: "",
      isSubmitting: false,
    });
    this.props.setUserValue({
      showAddToRoadmapModal: false,
      addToRoadmapStatus: "",
    });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleDetailsChange = (details) => {
    this.setState({ details: details });
  };

  handleSelectType = (event) => {
    this.setState({ type: event.target.value });
  };

  handleSelectStatus = (event) => {
    this.setState({ status: event.target.value });
  };

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: pictureFiles,
    });
  }

  onAddToRoadmap = async (event) => {
    this.setState({ isSubmitting: true });
    const { name, details, pictures, status, type } = this.state;
    const { user, subdomain } = this.props;
    const { currentUser } = user;

    if (name === "" || details === "" || status === "" || type === "") {
      toast.error("Oops, please fill out all required * fields.", {
        autoClose: 4000,
      });
      this.setState({ isSubmitting: false });
      return;
    }

    var newName = "";
    var tmpName = "";

    newName = name
      .toLowerCase()
      // eslint-disable-next-line no-useless-escape
      .replace(/([~!@#$%^&*()_+=`{}\[\]\|\\:;'<>,.\/? ])+/g, "-")
      .replace(/^(-)+|(-)+$/g, "");

    var requestRef = null;
    do {
      tmpName = newName + "-" + uuid().split("-")[0];
      // eslint-disable-next-line no-await-in-loop
      requestRef = await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(tmpName)
        .once("value");
    } while (requestRef && requestRef.exists() === true);
    newName = tmpName;

    const color = `hsl(${Math.floor(Math.random() * 360)}, 100%, 93%)`;

    var fileURL = "";
    if (pictures[0]) {
      var storageRef = await firebase.storage().ref("products");
      var photoName = pictures[0].name;
      var fileRef = storageRef
        .child(subdomain)
        .child("feedback")
        .child(type.toLowerCase())
        .child(photoName);

      var uploadedFile = await fileRef.put(pictures[0]);
      fileURL = await uploadedFile.ref.getDownloadURL();
    }

    const newRequest = {
      id: newName,
      submitter: {
        uid: currentUser.uid,
        avatar: currentUser.avatar,
        name: currentUser.displayName,
        email: currentUser.email || "",
        productName: currentUser.productName || "",
      },
      name: name,
      details: details,
      photo: fileURL,
      product: subdomain,
      type: type,
      color: color,
      stats: { replyCount: 0, viewCount: 1 },
      status: status,
      createdAt: Date.now(),
      priority: Date.now(),
      voteCount: 1,
    };

    await firebase //add to /posts/{companyId}/{postId}
      .database()
      .ref("company-posts")
      .child(subdomain)
      .child("active")
      .child(newName)
      .set(newRequest);

    const subdomainRef = await firebase
      .database()
      .ref("subdomains")
      .child(subdomain)
      .once("value");
    const admin = subdomainRef.val().admin;

    const statRef = await firebase
      .database()
      .ref("users")
      .child(admin.uid)
      .child("companies")
      .child(subdomain)
      .child("stats")
      .child(status.toLowerCase());
    statRef.transaction(function (count) {
      return (count || 0) + 1;
    });

    await firebase
      .database()
      .ref("company-posts")
      .child(subdomain)
      .child("active")
      .child(newName)
      .child("votedBy")
      .child(currentUser.uid)
      .set({
        avatar: currentUser.avatar,
        name: currentUser.displayName,
        email: currentUser.email || "",
      });

    await firebase //add to changelogs
      .database()
      .ref("company-posts")
      .child(subdomain)
      .child("active")
      .child(newName)
      .child("changeLogs")
      .push({
        status: status,
        comment: "",
        photo: "",
        notify: false,
        createdAt: Date.now(),
      });

    await firebase //push to /user-posts/{userId}/{requestId}
      .database()
      .ref("user-posts")
      .child(currentUser.uid)
      .child(subdomain)
      .child(newName)
      .set(newRequest);

    this.setState({ isSubmitting: false });
    this.handleModalClose();

    toast.success("Added successfully", {
      autoClose: 4000,
    });
  };

  render() {
    const { showAddToRoadmapModal } = this.props.user;
    const { isSubmitting, status, type } = this.state;

    const statuses = [
      {
        key: "PENDING",
        text: "Pending",
      },
      {
        key: "PLANNED",
        text: "Planned",
      },
      {
        key: "ONGOING",
        text: "Ongoing",
      },
    ];

    const types = [
      {
        key: "BUG",
        text: "🐞 Bug",
      },
      {
        key: "FEATURE",
        text: "🎉 Feature",
      },
      {
        key: "OTHER",
        text: "🤟Other",
      },
    ];

    return (
      <Modal
        show={showAddToRoadmapModal}
        onHide={this.handleModalClose}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add to Roadmap</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>
                Title <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Title"
                value={this.state.name}
                onChange={this.handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="status">
              <Form.Label>
                Status <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                value={status}
                onChange={this.handleSelectStatus}
              >
                <option value="">Choose...</option>
                {statuses.map((status) => {
                  return (
                    <option key={status.key} value={status.key}>
                      {status.text}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="category">
              <Form.Label>
                Category <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                value={type}
                onChange={this.handleSelectType}
              >
                <option value="">Choose...</option>
                {types.map((type) => {
                  return (
                    <option key={type.key} value={type.key}>
                      {type.text}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="details">
              <Form.Label>
                Details <span className="text-danger">*</span>
              </Form.Label>
              <SimpleMDE
                id="details"
                value={this.state.details}
                onChange={this.handleDetailsChange}
                options={{
                  autofocus: false,
                  spellChecker: false,
                  status: false,
                  onToggleFullScreen: false,
                  toolbar: [
                    "bold",
                    "italic",
                    "heading",
                    "|",
                    "quote",
                    "unordered-list",
                    "ordered-list",
                    "|",
                    "code",
                    "link",
                    "image",
                    "|",
                    "preview",
                  ],
                  renderingConfig: {
                    codeSyntaxHighlighting: true,
                  },
                }}
              />
              <div className="markdown-section">
                <div className="left">
                  <Form.Label
                    className="text-muted"
                    style={{ fontSize: "12px" }}
                  >
                    <i className="fab fa-markdown"></i> Markdown is supported. (
                    <a
                      href="https://www.markdownguide.org/cheat-sheet/"
                      target="_blank"
                    >
                      cheatsheet
                    </a>
                    )
                  </Form.Label>
                </div>
              </div>
            </Form.Group>

            <ImageUploader
              withIcon={true}
              buttonText="Choose an image"
              label="Max file size: 20MB, accepted: jpg, gif, png"
              onChange={this.onDrop}
              imgExtension={[".jpg", ".gif", ".png"]}
              maxFileSize={20971520}
              singleImage={true}
              withPreview={true}
            />
          </Form>
          <button
            onClick={this.onAddToRoadmap}
            className="btn btn-primary px-5 my-1"
            disabled={isSubmitting ? true : false}
          >
            {isSubmitting ? (
              <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AddToRoadmap);
