import React from "react";

class PostVoters extends React.Component {
  render() {
    const { post } = this.props;

    var votersArray = Object.values(post.votedBy);
    return (
      <>
        <div className="post-sub-title">Voted by</div>
        {votersArray &&
          votersArray.map((voter) => {
            return (
              <img
                src={voter.avatar}
                alt=""
                style={{ width: "35px", marginRight: "10px" }}
                className="logo-img"
                key={voter.email}
              />
            );
          })}
      </>
    );
  }
}

export default PostVoters;
