import React from "react";
import { Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { uuid } from "uuidv4";
import { toast } from "react-toastify";
import ImageUploader from "react-images-upload";
import PulseLoader from "react-spinners/PulseLoader";
import { setUserValue } from "../../redux/action/userAction";
import "./NewFormModal.css";

class AddNewBug extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      photo: "",
      pictures: [],
      name: "",
      details: "",
      isSubmitting: false,
    };

    this.onDrop = this.onDrop.bind(this);
    this.handleLaunchChange = this.handleLaunchChange.bind(this);
  }

  handleLaunchChange(isLaunched) {
    this.setState({ isLaunched });
  }

  handleModalClose = () => {
    this.setState({
      loading: false,
      photo: "",
      name: "",
      details: "",
      isSubmitting: false,
    });
    this.props.setUserValue({ showAddNewFeedbackModal: false });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: pictureFiles,
    });
  }

  submitNewFeedback = async (event) => {
    this.setState({ isSubmitting: true });
    const { name, details, photo, pictures } = this.state;
    const { user, subdomain } = this.props;
    const { currentUser } = user;

    if (name === "" || details === "") {
      toast.error("Oops, Name and Details cannot be empty.", {
        autoClose: 4000,
      });
      this.setState({ isSubmitting: false });
      return;
    }

    var newName = "";
    var tmpName = "";

    newName = name
      .toLowerCase()
      // eslint-disable-next-line no-useless-escape
      .replace(/([~!@#$%^&*()_+=`{}\[\]\|\\:;'<>,.\/? ])+/g, "-")
      .replace(/^(-)+|(-)+$/g, "");

    var requestRef = null;
    do {
      tmpName = newName + "-" + uuid().split("-")[0];
      // eslint-disable-next-line no-await-in-loop
      requestRef = await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(tmpName)
        .once("value");
    } while (requestRef && requestRef.exists() === true);
    newName = tmpName;

    const color = `hsl(${Math.floor(Math.random() * 360)}, 100%, 93%)`;

    var fileURL = "";
    if (pictures[0]) {
      var storageRef = await firebase.storage().ref("products");
      var photoName = pictures[0].name;
      var fileRef = storageRef
        .child(subdomain)
        .child("feedback")
        .child("other")
        .child(photoName);

      var uploadedFile = await fileRef.put(pictures[0]);
      fileURL = await uploadedFile.ref.getDownloadURL();
    }

    const newRequest = {
      id: newName,
      submitter: {
        uid: currentUser.uid,
        avatar: currentUser.avatar,
        name: currentUser.displayName,
        email: currentUser.email || "",
        productName: currentUser.productName || "",
      },
      name: name,
      details: details,
      photo: fileURL,
      product: subdomain,
      type: "OTHER",
      color: color,
      stats: { replyCount: 0, viewCount: 1 },
      status: "PENDING",
      createdAt: Date.now(),
      priority: Date.now(),
      voteCount: 1,
    };

    await firebase //add to /posts/{companyId}/{postId}
      .database()
      .ref("company-posts")
      .child(subdomain)
      .child("active")
      .child(newName)
      .set(newRequest);

    const subdomainRef = await firebase
      .database()
      .ref("subdomains")
      .child(subdomain)
      .once("value");
    const admin = subdomainRef.val().admin;

    const pendingRef = await firebase
      .database()
      .ref("users")
      .child(admin.uid)
      .child("companies")
      .child(subdomain)
      .child("stats")
      .child("pending");
    pendingRef.transaction(function (count) {
      return (count || 0) + 1;
    });

    const usageRef = await firebase
      .database()
      .ref("users")
      .child(admin.uid)
      .child("usage");
    usageRef.transaction(function (usageCount) {
      return (usageCount || 0) + 1;
    });

    const totalSubmissionRef = await firebase
      .database()
      .ref("users")
      .child(admin.uid)
      .child("totalSubmission");
    totalSubmissionRef.transaction(function (count) {
      return (count || 0) + 1;
    });

    await firebase
      .database()
      .ref("company-posts")
      .child(subdomain)
      .child("active")
      .child(newName)
      .child("votedBy")
      .child(currentUser.uid)
      .set({
        avatar: currentUser.avatar,
        name: currentUser.displayName,
        email: currentUser.email || "",
      });

    await firebase //add to changelogs
      .database()
      .ref("company-posts")
      .child(subdomain)
      .child("active")
      .child(newName)
      .child("changeLogs")
      .push({
        status: "PENDING",
        comment: "",
        photo: "",
        notify: false,
        createdAt: Date.now(),
      });

    await firebase //push to /user-posts/{userId}/{requestId}
      .database()
      .ref("user-posts")
      .child(currentUser.uid)
      .child(subdomain)
      .child(newName)
      .set(newRequest);

    await firebase //push to project owner's notifications
      .database()
      .ref("notifications")
      .child(admin.uid)
      .push({
        type: "other",
        project: subdomainRef.val().name,
        user: {
          avatar: currentUser.avatar,
          name: currentUser.displayName,
        },
        link: "https://" + window.location.host + "/post/" + newName,
        createdAt: Date.now(),
      });

    this.setState({ isSubmitting: false });
    this.props.setUserValue({
      showAddNewBugModal: false,
      showAddNewFeatureModal: false,
      showAddNewFeedbackModal: false,
      showUpdatePostStatusModal: false,
      showUpdateFeedbackModal: false,
      showDeleteFeedbackModal: false,
    });
    toast.success("Feedback submitted successfully", {
      autoClose: 4000,
    });

    this.props.history.push("/post/" + newName);
  };

  render() {
    const { showAddNewFeedbackModal } = this.props.user;
    const { photo, isSubmitting } = this.state;

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Modal
        show={showAddNewFeedbackModal}
        onHide={this.handleModalClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Submit your feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="A short title"
                value={this.state.name}
                onChange={this.handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="details">
              <Form.Label>Details</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows="7"
                placeholder="Any additional details"
                value={this.state.details}
                onChange={this.handleInputChange}
              />
              <div className="markdown-section">
                <Form.Label className="text-muted" style={{ fontSize: "12px" }}>
                  <i class="fab fa-markdown"></i> Markdown is supported. (
                  <a
                    href="https://www.markdownguide.org/cheat-sheet/"
                    target="_blank"
                  >
                    cheatsheet
                  </a>
                  )
                </Form.Label>
              </div>
            </Form.Group>

            <ImageUploader
              withIcon={true}
              buttonText="Choose an image"
              label="Max file size: 20MB, accepted: jpg, gif, png"
              onChange={this.onDrop}
              imgExtension={[".jpg", ".gif", ".png"]}
              maxFileSize={20971520}
              singleImage={true}
              withPreview={true}
            />
          </Form>
          <button
            onClick={this.submitNewFeedback}
            className="btn btn-primary px-5 my-1"
            disabled={isSubmitting ? true : false}
          >
            {isSubmitting ? (
              <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AddNewBug);
