import React, { Component } from "react";
import { Comment, Header } from "semantic-ui-react";
import AddCommentForm from "./AddCommentForm";
import firebase from "firebase/app";
import "firebase/database";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { toast } from "react-toastify";
import RenderComments from "./RenderComments";
import { setUserValue } from "../../redux/action/userAction";

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReplyForm: false,
    };
    console.log(this.props.user.comment);
  }

  addPostComment = async (postId, comment, parentId) => {
    const { user, subdomain, post } = this.props;
    const { currentUser } = user;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true, comment: comment });
      return "loginNeeded";
    }

    if (comment === "") {
      toast.error("Sorry, comment cannot be empty :(", {
        autoClose: 4000,
      });
      return;
    }

    let newComment = {
      parentId: parentId,
      user: {
        displayName: currentUser.displayName,
        photoURL: currentUser.avatar,
        uid: currentUser.uid,
        email: currentUser.email || "",
      },
      postOwner: post.submitter,
      postStatus: post.status,
      comment: comment,
      date: Date.now(),
    };

    try {
      await firebase
        .database()
        .ref("comments")
        .child(subdomain)
        .child(postId)
        .push(newComment);

      var status = "active";
      if (post.status === "COMPLETED") {
        status = "completed";
      }
      if (post.status === "NOT AN ISSUE") {
        status = "closed";
      }

      //replyCount++
      const postRef = await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child(status)
        .child(postId)
        .child("stats")
        .child("replyCount");
      postRef.transaction(function (currentReplyCount) {
        return (currentReplyCount || 0) + 1;
      });
    } catch (error) {
      await firebase.database().ref("errors").push({
        where: "addPostComment in Comments",
        error: error,
        date: Date.now(),
      });
    }

    this.props.setUserValue({ comment: "" });
  };

  render() {
    const { post, comments, user, subdomain } = this.props;
    const { currentUser } = user;

    return (
      <div>
        <AddCommentForm
          parentId={0}
          allowCancel={false}
          postId={post.id}
          addPostComment={this.addPostComment}
          setUserValue={setUserValue}
          currentUser={currentUser}
          parentComment={true}
        />
        <Comment.Group threaded>
          {comments &&
            comments.map((comment) => {
              return (
                <RenderComments
                  key={comment.id}
                  comment={comment}
                  currentUser={currentUser}
                  postId={post.id}
                  post={post}
                  subdomain={subdomain}
                />
              );
            })}
        </Comment.Group>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Comments);
