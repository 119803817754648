import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/database";
import PulseLoader from "react-spinners/PulseLoader";
import Confetti from "react-confetti";
import UpdateFeedbackModal from "../../components/modals/UpdateFeedbackModal";
import DeleteFeedbackModal from "../../components/modals/DeleteFeedbackModal";
import PostContent from "./PostContent";
import PostChangeLogs from "./PostChangeLogs";
import PostVoters from "./PostVoters";
import Comments from "./Comments";
import {
  objectToArray,
  createCommentsTree,
} from "../../components/utils/CommentHelper";
import "./PostDetails.css";

var needUnmount = true;
var postListener;
var commentsListener;

class PostDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      post: null,
      comments: [],
      justCompleted:
        (this.props.location.state &&
          this.props.location.state.justCompleted) ||
        false,
    };
  }

  componentDidMount = async () => {
    var postId = this.props.match.params.postId;

    const { subdomain, companyExists } = this.props;
    if (!companyExists) {
      this.props.history.push("/error");
      return;
    }

    var status = "active";
    if (this.props.postStatus === "COMPLETED") {
      status = "completed";
    }
    if (this.props.postStatus === "NOT AN ISSUE") {
      status = "closed";
    }

    postListener = await firebase
      .database()
      .ref("company-posts")
      .child(subdomain)
      .child(status)
      .child(postId)
      .on("value", (snapshot) => {
        if (snapshot.val() === null || snapshot.val() === undefined) {
          this.props.history.push("/error");
        } else {
          this.setState({ post: snapshot.val() });
        }
      });

    commentsListener = firebase
      .database()
      .ref("comments")
      .child(subdomain)
      .child(postId)
      .on("value", (snapshot) => {
        if (snapshot) {
          this.setState({ comments: objectToArray(snapshot.val()) });
        }
      });
  };

  componentWillUnmount = async () => {
    var postId = this.props.match.params.postId;

    var status = "active";
    if (this.props.postStatus === "COMPLETED") {
      status = "completed";
    }
    if (this.props.postStatus === "NOT AN ISSUE") {
      status = "closed";
    }

    await firebase
      .database()
      .ref("company-posts")
      .child(this.props.subdomain)
      .child(status)
      .child(postId)
      .off("value", postListener);
  };

  goBack = () => {
    if (
      this.props.postStatus === "COMPLETED" ||
      this.props.postStatus === "NOT AN ISSUE"
    ) {
      this.props.history.push("/");
    } else {
      this.props.history.goBack();
    }
  };

  render() {
    const { comments, post, justCompleted } = this.state;
    const { subdomain } = this.props;
    const subdomainCap = subdomain.charAt(0).toUpperCase() + subdomain.slice(1);

    const commentsTree =
      comments && Object.keys(comments).length > 0
        ? createCommentsTree(comments)
        : [];

    if (!post)
      return (
        <div className="text-center" style={{ marginTop: 150 }}>
          <PulseLoader sizeUnit={"px"} size={8} color={"#66b0ff"} />
        </div>
      );

    return (
      <Fragment>
        <Helmet>
          <title>{post.name + " | " + subdomainCap}</title>
          <meta
            name="description"
            content={
              "Report bugs or request features for " +
              subdomainCap +
              " on backlogs.co"
            }
          />
        </Helmet>
        <UpdateFeedbackModal subdomain={subdomain} post={post} />
        <DeleteFeedbackModal subdomain={subdomain} post={post} />

        {justCompleted && (
          <Confetti style={{ position: "fixed" }} recycle={false} />
        )}

        <section className="bg-postdetails">
          <div className="container">
            <button
              className="back mx-0"
              onClick={() => this.goBack()}
              style={{ width: "100%" }}
            >
              👈 Go back
            </button>
            <PostContent post={post} subdomain={subdomain} />

            {post.votedBy && <PostVoters post={post} />}

            {post.changeLogs && <PostChangeLogs post={post} />}

            <Comments
              subdomain={subdomain}
              post={post}
              comments={commentsTree}
              count={comments ? Object.keys(comments).length : 0}
            />
          </div>
        </section>
      </Fragment>
    );
  }
}

export default withRouter(PostDetails);
