import React from "react";
import { Icon } from "semantic-ui-react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import PulseLoader from "react-spinners/PulseLoader";
import { toast } from "react-toastify";
import { Row, Col } from "reactstrap";
import Cookies from "js-cookie";
import "./Login.css";
import { withRouter } from "react-router";

const hostURL = window.location.host.split(".").reverse();
const cookieDomain =
  hostURL.length > 1 ? "." + hostURL[1] + "." + hostURL[0] : null;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signUp: false,
      isLoading: false,
      email: "",
      password: "",
      confirmPassword: "",
      name: "",
      errors: [],
      userNotFound: false,
      invalidPassword: false,
      elseError: false,
      passwordNotMatch: false,
    };
    this.onChangeText = this.onChangeText.bind(this);
  }

  onBlur = (event) => {
    const { errors } = this.state;
    if (event.target.value) {
      delete errors[event.target.name];
      this.setState({
        errors,
        [event.target.name]: event.target.value,
      });
    } else {
      errors[event.target.name] = true;
      this.setState({ errors });
    }
  };

  onChangeText = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onLoginForm = () => {
    this.setState({
      email: "",
      password: "",
      confirmPassword: "",
      name: "",
      errors: [],
      signUp: false,
      userNotFound: false,
      invalidPassword: false,
      elseError: false,
      passwordNotMatch: false,
    });
  };

  onSignupForm = () => {
    this.setState({
      email: "",
      password: "",
      confirmPassword: "",
      name: "",
      errors: [],
      signUp: true,
      userNotFound: false,
      invalidPassword: false,
      elseError: false,
      passwordNotMatch: false,
    });
  };

  onLogIn = async () => {
    const { email, password } = this.state;
    this.setState({
      isLoading: true,
      userNotFound: false,
      invalidPassword: false,
      elseError: false,
      passwordNotMatch: false,
    });
    try {
      if (cookieDomain) {
        Cookies.set("_fb_uid", "init", {
          path: "",
          domain: cookieDomain,
        });
        Cookies.set("_fb_token", "init", {
          path: "",
          domain: cookieDomain,
        });
      } else {
        Cookies.set("_fb_uid", "init");
        Cookies.set("_fb_token", "init");
      }

      const response = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      toast.success("You've logged in successfully! 👋", {
        autoClose: 4000,
      });

      this.props.history.goBack();

      var userSignIn = await firebase.functions().httpsCallable("userSignIn");
      const token = await userSignIn({
        userUid: response.user.uid,
      });

      if (cookieDomain) {
        Cookies.set("_fb_uid", response.user.uid, {
          path: "",
          domain: cookieDomain,
        });
        Cookies.set("_fb_token", token.data, {
          path: "",
          domain: cookieDomain,
        });
      } else {
        Cookies.set("_fb_uid", response.user.uid);
        Cookies.set("_fb_token", token.data);
      }

      this.setState({ isLoading: false });
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        this.setState({ userNotFound: true });
      } else if (error.code === "auth/invalid-password") {
        this.setState({ invalidPassword: true });
      } else {
        this.setState({ elseError: true });
      }
      this.setState({ isLoading: false });
    }
  };

  onSignUp = async () => {
    this.setState({
      userNotFound: false,
      invalidPassword: false,
      elseError: false,
      passwordNotMatch: false,
    });
    const { email, password, confirmPassword, name } = this.state;
    if (password !== confirmPassword) {
      toast.error("Sorry, passwords do not match.", {
        autoClose: 4000,
      });
      return;
    }

    this.setState({ isLoading: true });
    try {
      if (cookieDomain) {
        Cookies.set("_fb_uid", "init", {
          path: "",
          domain: cookieDomain,
        });
        Cookies.set("_fb_token", "init", {
          path: "",
          domain: cookieDomain,
        });
      } else {
        Cookies.set("_fb_uid", "init");
        Cookies.set("_fb_token", "init");
      }

      const response = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      if (response.additionalUserInfo.isNewUser) {
        const userUid = response.user.uid;
        const userObject = {
          avatar:
            "https://iupac.org/wp-content/uploads/2018/05/default-avatar.png",
          email: response.user.email,
          uid: userUid,
          displayName: name,
          createdAt: Date.now(),
          signUpVia: "email",
          emailVerified: false,
        };

        await firebase.database().ref("users").child(userUid).set(userObject);

        var userSignIn = await firebase.functions().httpsCallable("userSignIn");
        const token = await userSignIn({
          userUid: response.user.uid,
        });

        if (cookieDomain) {
          Cookies.set("_fb_uid", response.user.uid, {
            path: "",
            domain: cookieDomain,
          });
          Cookies.set("_fb_token", token.data, {
            path: "",
            domain: cookieDomain,
          });
        } else {
          Cookies.set("_fb_uid", response.user.uid);
          Cookies.set("_fb_token", token.data);
        }

        toast.success("You've signed up successfully! 👋", {
          autoClose: 4000,
        });
      }
      this.setState({ isLoading: false });
      this.props.history.push("/");
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        this.setState({ userNotFound: true });
      } else if (error.code === "auth/invalid-password") {
        this.setState({ invalidPassword: true });
      } else {
        this.setState({ elseError: true });
      }
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      signUp,
      isLoading,
      email,
      password,
      confirmPassword,
      name,
      errors,
      userNotFound,
      invalidPassword,
      elseError,
      passwordNotMatch,
    } = this.state;
    return (
      <React.Fragment>
        <main className="bg-light login-container">
          <div className="headerSpace container"></div>
          <div className="main">
            <div className="container mt-5 px-5">
              <div className="container">
                <Row className="justify-content-center mt-5">
                  <Col lg={7} md={7}>
                    {signUp ? (
                      <div className="a-content">
                        <div className="a-signin-header">
                          <span>
                            <Icon
                              name="user outline"
                              size="big"
                              style={{
                                marginBottom: "24px",
                                color: "#2f55d4",
                              }}
                            />
                          </span>
                          <div className="a-signin-label">Sign up</div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="a-text-box">
                              <label>
                                Your name :{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="name"
                                className="a-text-box-content"
                                placeholder="Your name"
                                onChange={this.onChangeText}
                                onBlur={this.onBlur}
                                value={name}
                              />
                              {errors.name ? (
                                <span className="a-error">
                                  Your Name Required
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="a-text-box">
                              <label>
                                Email : <span className="text-danger">*</span>
                              </label>
                              <input
                                name="email"
                                type="email"
                                className="a-text-box-content"
                                placeholder="Email"
                                onChange={this.onChangeText}
                                onBlur={this.onBlur}
                                value={email}
                              />
                              {errors.email ? (
                                <span className="a-error">Email Required</span>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="a-text-box">
                              <label>
                                Password :{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="password"
                                type="password"
                                className="a-text-box-content"
                                placeholder="password"
                                onChange={this.onChangeText}
                                onBlur={this.onBlur}
                                value={password}
                              />
                              {errors.password ? (
                                <span className="a-error">
                                  Password Required
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="a-row">
                          <div className="a-col-md-12">
                            <div className="a-text-box">
                              <label>
                                Confirm password :{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="confirmPassword"
                                type="password"
                                className="a-text-box-content"
                                placeholder="password"
                                onChange={this.onChangeText}
                                onBlur={this.onBlur}
                                value={confirmPassword}
                              />
                              {errors.confirmPassword ? (
                                <span className="a-error">
                                  Please enter your password again
                                </span>
                              ) : null}
                              {passwordNotMatch ? (
                                <span className="a-error">
                                  Password didn't match with above.
                                </span>
                              ) : null}
                              {invalidPassword ? (
                                <span className="a-error">
                                  Invalid password. Minimum 6 characters.
                                </span>
                              ) : null}
                              {elseError ? (
                                <span className="a-error">
                                  Error, please try it again.
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <button
                          onClick={this.onSignUp}
                          className={
                            email === "" ||
                            name === "" ||
                            password === "" ||
                            confirmPassword === ""
                              ? "a-btn-disabled"
                              : "a-btn"
                          }
                          disabled={
                            email === "" ||
                            name === "" ||
                            password === "" ||
                            confirmPassword === ""
                              ? true
                              : isLoading
                              ? true
                              : false
                          }
                        >
                          {isLoading ? (
                            <PulseLoader
                              sizeUnit={"px"}
                              size={8}
                              color={"#fff"}
                            />
                          ) : (
                            "Sign up"
                          )}
                        </button>
                        <div
                          className="a-label a-cursor-pointer"
                          style={{ textAlign: "center" }}
                          onClick={this.onLoginForm}
                        >
                          Already have an account ?{" "}
                          <span style={{ color: "#2443ac" }}>Log in here</span>.
                        </div>
                      </div>
                    ) : (
                      <div className="a-content">
                        <div className="a-signin-header">
                          <span>
                            <Icon
                              name="user outline"
                              size="big"
                              style={{
                                marginBottom: "24px",
                                color: "#2f55d4",
                              }}
                            />
                          </span>
                          <div className="a-signin-label">Log in</div>
                        </div>
                        <div className="a-row">
                          <div className="a-col-md-12">
                            <div className="a-text-box">
                              <label>
                                Email : <span className="text-danger">*</span>
                              </label>
                              <input
                                name="email"
                                type="email"
                                className="a-text-box-content"
                                placeholder="Email"
                                onChange={this.onChangeText}
                                onBlur={this.onBlur}
                                value={email}
                              />
                              {errors.email ? (
                                <span className="a-error">Email required</span>
                              ) : null}
                              {userNotFound ? (
                                <span className="a-error">
                                  You haven't registered yet. Please sign up
                                  first.
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="a-row">
                          <div className="a-col-md-12">
                            <div className="a-text-box">
                              <label>
                                Password :{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="password"
                                type="password"
                                className="a-text-box-content"
                                placeholder="password"
                                onChange={this.onChangeText}
                                onBlur={this.onBlur}
                                value={password}
                              />
                              {errors.password ? (
                                <span className="a-error">
                                  Password required
                                </span>
                              ) : null}
                              {invalidPassword ? (
                                <span className="a-error">
                                  Invalid password. Minimum 6 characters.
                                </span>
                              ) : null}
                              {elseError ? (
                                <span className="a-error">
                                  Error, please try it again.
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <button
                          onClick={this.onLogIn}
                          className={
                            email === "" || password === ""
                              ? "a-btn-disabled"
                              : "a-btn"
                          }
                          disabled={
                            email === "" || password === ""
                              ? true
                              : isLoading
                              ? true
                              : false
                          }
                          id="send-me-code"
                        >
                          {isLoading ? (
                            <PulseLoader
                              sizeUnit={"px"}
                              size={8}
                              color={"#fff"}
                            />
                          ) : (
                            "Log in"
                          )}
                        </button>
                        <div
                          className="a-label a-cursor-pointer"
                          style={{ textAlign: "center" }}
                          onClick={this.onSignupForm}
                        >
                          New to Backlogs ?{" "}
                          <span style={{ color: "#2443ac" }}>Sign up here</span>
                          .
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default withRouter(Login);
