import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { setUserValue } from "../../redux/action/userAction";
import Card from "./Card";

class Board extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addToRoadmap = (title) => {
    this.props.setUserValue({
      showAddToRoadmapModal: true,
      addToRoadmapStatus: title.toUpperCase(),
    });
  };

  render() {
    const { title, cards, subdomain, user } = this.props;
    const { currentUser } = user;

    var color;
    if (title === "Pending") {
      color = "#85b5b5";
    } else if (title === "Planned") {
      color = "#4e5aff";
    } else if (title === "Ongoing") {
      color = "#65c275";
    }

    return (
      <Droppable droppableId={title}>
        {(provided, snapshot) => {
          const style = {
            backgroundColor: snapshot.isDraggingOver
              ? "#ffebe6"
              : "transparent",
            transition: "background-color .2s ease",
            ...provided.droppableProps.style,
          };
          return (
            <div
              className="board-column"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <div className="card-header">
                <h5 className="card-title">
                  <div className="left">
                    {title}
                    <span className="text-muted ml-3">{cards.length}</span>
                  </div>
                  {currentUser &&
                    currentUser.companies &&
                    currentUser.companies[subdomain] && (
                      <div className="right">
                        <button
                          className="btn"
                          onClick={() => this.addToRoadmap(title)}
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    )}
                </h5>
                <hr
                  className="title-underline"
                  style={{ float: "left", borderColor: color }}
                ></hr>
              </div>
              <div className="card bg-light board-card">
                <div className="p-3 card-body" style={style}>
                  {cards.map((card, index) => {
                    return (
                      <Card
                        key={card.id}
                        card={card}
                        index={index}
                        subdomain={subdomain}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              </div>
            </div>
          );
        }}
      </Droppable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Board);
