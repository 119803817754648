import React, { Component } from "react";
import { Comment } from "semantic-ui-react";
import AddCommentForm from "./AddCommentForm";
import firebase from "firebase/app";
import "firebase/database";
import { formatDistance } from "date-fns";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import Linkify from "linkifyjs/react";
import { toast } from "react-toastify";
import ReactMarkdown from "react-markdown";
import { setUserValue } from "../../redux/action/userAction";
import CodeBlock from "../../components/utils/CodeBlock";

class RenderComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReplyForm: false,
      selectedCommentId: null,
      isEditComment: false,
    };
  }

  handleCloseReplyForm = () => {
    this.setState({
      selectedCommentId: null,
      showReplyForm: false,
      isEditComment: false,
    });
  };

  handleOpenReplyForm = (id) => () => {
    const { currentUser, setUserValue } = this.props;
    if (!currentUser) {
      setUserValue({ showSignInModal: true });
    } else {
      this.setState({
        showReplyForm: true,
        selectedCommentId: id,
      });
    }
  };

  handleOpenEditReplyForm = (id) => () => {
    const { currentUser, setUserValue } = this.props;
    if (!currentUser) {
      setUserValue({ showSignInModal: true });
    } else {
      this.setState({
        showReplyForm: true,
        selectedCommentId: id,
        isEditComment: true,
      });
    }
  };

  addPostComment = async (postId, comment, parentId) => {
    const { currentUser, subdomain, post } = this.props;
    if (!currentUser) {
      toast.error("Sorry, You have to log in first. :(", {
        autoClose: 4000,
      });
      return alert("You have to log in first.");
    }
    if (comment === "") {
      toast.error("Sorry, comment cannot be empty :(", {
        autoClose: 4000,
      });
      return;
    }

    let newComment = {
      parentId: parentId,
      user: {
        displayName: currentUser.displayName,
        photoURL: currentUser.avatar,
        uid: currentUser.uid,
        email: currentUser.email || "",
      },
      comment: comment,
      postStatus: post.status,
      date: Date.now(),
    };

    try {
      await firebase
        .database()
        .ref("comments")
        .child(subdomain)
        .child(postId)
        .push(newComment);

      //replyCount++
      var status = "active";
      if (post.status === "COMPLETED") {
        status = "completed";
      }
      if (post.status === "NOT AN ISSUE") {
        status = "closed";
      }

      const postRef = await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child(status)
        .child(postId)
        .child("stats")
        .child("replyCount");
      postRef.transaction(function (currentReplyCount) {
        return (currentReplyCount || 0) + 1;
      });
    } catch (error) {
      await firebase.database().ref("errors").push({
        where: "addPostComment in RenderComments",
        error: error,
      });
    }
  };

  editPostComment = async (postId, comment, parentId) => {
    const { currentUser, subdomain } = this.props;
    if (!currentUser) {
      toast.error("Sorry, You have to log in first. :(", {
        autoClose: 4000,
      });
      return alert("You have to log in first.");
    }
    if (comment === "") {
      toast.error("Sorry, comment cannot be empty :(", {
        autoClose: 4000,
      });
      return;
    }

    try {
      await firebase
        .database()
        .ref("comments")
        .child(subdomain)
        .child(postId)
        .child(parentId)
        .update({ comment: comment, updatedAt: Date.now() });
    } catch (error) {
      await firebase.database().ref("errors").push({
        where: "editPostComment in RenderComments",
        error: error,
      });
    }
  };

  render() {
    const {
      postId,
      comment,
      currentUser,
      setUserValue,
      subdomain,
      post,
    } = this.props;

    const { showReplyForm, selectedCommentId, isEditComment } = this.state;
    var nestedComments;
    if (comment && comment.childNodes && comment.childNodes.length > 0) {
      nestedComments = (
        <Comment.Group threaded>
          {(comment.childNodes || []).map((comment) => {
            return (
              <RenderComments
                key={comment.id}
                comment={comment}
                currentUser={currentUser}
                setUserValue={setUserValue}
                postId={postId}
                subdomain={subdomain}
                post={post}
              />
            );
          })}
        </Comment.Group>
      );
    } else {
      nestedComments = null;
    }

    return (
      <>
        <Comment key={comment.id}>
          <Comment.Avatar src={comment.user.photoURL || "/assets/user.png"} />
          {!isEditComment ? (
            <Comment.Content>
              <Comment.Author>
                {comment.user.displayName}
                <Comment.Metadata style={{ fontWeight: 400 }}>
                  <div>{formatDistance(comment.date, Date.now())} ago</div>
                </Comment.Metadata>
              </Comment.Author>

              <Comment.Text>
                <Linkify style={{ whiteSpace: "pre-line" }}>
                  <ReactMarkdown
                    source={comment.comment}
                    renderers={{ code: CodeBlock }}
                  />
                </Linkify>
              </Comment.Text>
              <Comment.Actions>
                <Comment.Action onClick={this.handleOpenReplyForm(comment.id)}>
                  Reply
                </Comment.Action>
                {currentUser &&
                  comment &&
                  currentUser.uid === comment.user.uid && (
                    <Comment.Action
                      onClick={this.handleOpenEditReplyForm(comment.id)}
                    >
                      Edit
                    </Comment.Action>
                  )}
                {showReplyForm && selectedCommentId === comment.id && (
                  <AddCommentForm
                    addPostComment={this.addPostComment}
                    postId={postId}
                    closeForm={this.handleCloseReplyForm}
                    parentId={comment.id}
                    allowCancel={true}
                    parentComment={false}
                    isEditComment={isEditComment}
                    comment={comment}
                  />
                )}
              </Comment.Actions>
            </Comment.Content>
          ) : (
            <Comment.Content>
              <Comment.Author>
                {comment.user.displayName}
                <Comment.Metadata style={{ fontWeight: 400 }}>
                  <div>{formatDistance(comment.date, Date.now())} ago</div>
                </Comment.Metadata>
              </Comment.Author>
              {showReplyForm && comment && selectedCommentId === comment.id && (
                <AddCommentForm
                  addPostComment={this.addPostComment}
                  editPostComment={this.editPostComment}
                  postId={postId}
                  closeForm={this.handleCloseReplyForm}
                  parentId={comment.id}
                  allowCancel={true}
                  parentComment={false}
                  isEditComment={isEditComment}
                  comment={comment}
                />
              )}
            </Comment.Content>
          )}
          {nestedComments}
        </Comment>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RenderComments);
