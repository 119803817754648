import React from "react";
import { Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { setUserValue } from "../../redux/action/userAction";
import "./NewFormModal.css";

class DeleteChangelogModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isSubmitting: false,
    };
  }

  handleModalClose = () => {
    this.setState({
      loading: false,
      isSubmitting: false,
    });
    this.props.setUserValue({ showDeleteChangelogModal: false });
  };

  onDeleteChangelog = async (event) => {
    this.setState({ isSubmitting: true });
    const { subdomain, changelog } = this.props;

    await firebase //push to /user-posts/{userId}/{requestId}
      .database()
      .ref("company-changelogs")
      .child(subdomain)
      .child(changelog.url)
      .remove();

    this.setState({ isSubmitting: false });
    this.props.setUserValue({
      showDeleteChangelogModal: false,
    });

    toast.success("Changelog deleted successfully", {
      autoClose: 4000,
    });

    this.props.history.push("/changelog");
  };

  render() {
    const { showDeleteChangelogModal } = this.props.user;
    const { isSubmitting } = this.state;

    return (
      <Modal
        show={showDeleteChangelogModal}
        onHide={this.handleModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete this changelog?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6 text-center">
              <button
                className=" btn btn-primary px-5 my-1"
                onClick={() => {
                  this.onDeleteChangelog();
                }}
              >
                {isSubmitting ? (
                  <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                ) : (
                  "Delete it"
                )}
              </button>
            </div>
            <div className="col-6 text-center">
              <button
                className="btn btn-secondary px-5"
                onClick={() => this.handleModalClose()}
              >
                Go back
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DeleteChangelogModal);
